import {
  Box,
  Center,
  Container,
  Grid,
  Loader,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import {
  CreatorCard,
  CreatorSummary,
} from "../../components/Cards/CreatorCard";
import FilterPanel, {
  FilterOption,
  SortOption,
} from "../../components/Filter/FilterPanel";
import { mockCreators } from "../../utils/mockData/creatorsMockData";

const DiscoverCreatorsPage: React.FC = () => {
  const [creators, setCreators] = useState<CreatorSummary[]>([]);
  const [filteredCreators, setFilteredCreators] = useState<CreatorSummary[]>(
    []
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("rating");
  const [loading, setLoading] = useState(true);

  // Filter state
  const [platforms, setPlatforms] = useState<string[]>([]);
  const [minFollowers, setMinFollowers] = useState<number | "">("");
  const [monthlyGMV, setMonthlyGMV] = useState<number | "">("");
  const [minRating, setMinRating] = useState(0);

  // Responsive handling
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 992px) and (min-width: 769px)");

  useEffect(() => {
    // In a real app, this would be an API call
    // For now, using mock data
    setCreators(mockCreators);
    setLoading(false);
  }, []);

  const handleClearFilters = () => {
    setSearchTerm("");
    setPlatforms([]);
    setMinFollowers("");
    setMonthlyGMV("");
    setMinRating(0);
    setSortBy("rating");
  };

  const filterOptions: FilterOption[] = [
    {
      id: "search",
      label: "Search",
      type: "search",
      placeholder: "Search creators by name or description",
      value: searchTerm,
      onChange: setSearchTerm,
      searchFields: ["name", "description"],
    },
    {
      id: "platforms",
      label: "Platforms",
      type: "multiSelect",
      placeholder: "Select platforms",
      data: [
        { value: "tiktok", label: "TikTok" },
        { value: "instagram", label: "Instagram" },
        { value: "youtube", label: "YouTube" },
        { value: "twitter", label: "Twitter" },
      ],
      value: platforms,
      onChange: setPlatforms,
      filterFn: (creator: CreatorSummary, selectedPlatforms: string[]) => {
        if (selectedPlatforms.length === 0) return true;
        return selectedPlatforms.some(
          (platform) =>
            creator.socialMedia[platform as keyof typeof creator.socialMedia]
        );
      },
    },
    {
      id: "minFollowers",
      label: "Min Followers",
      type: "numberInput",
      placeholder: "e.g. 10,000",
      suffix: "+",
      value: minFollowers,
      onChange: setMinFollowers,
      filterFn: (creator: CreatorSummary, minValue: number | "") => {
        if (minValue === "") return true;
        const totalFollowers = Object.values(creator.socialMedia).reduce(
          (sum, val) => sum + (val || 0),
          0
        );
        return totalFollowers >= minValue;
      },
    },
    {
      id: "monthlyGMV",
      label: "Monthly GMV",
      type: "numberInput",
      placeholder: "e.g. 5,000",
      suffix: "$+",
      value: monthlyGMV,
      onChange: setMonthlyGMV,
      filterFn: (creator: CreatorSummary, minValue: number | "") => {
        if (minValue === "") return true;
        return (creator.monthlyGMV || 0) >= minValue;
      },
    },
    {
      id: "minRating",
      label: "Minimum Rating",
      type: "slider",
      min: 0,
      max: 5,
      step: 0.1,
      marks: [
        { value: 0, label: "0" },
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
      ],
      value: minRating,
      onChange: setMinRating,
      field: "rating",
    },
  ];

  const sortOptions: SortOption[] = [
    {
      value: "rating",
      label: "Highest Rating",
      sortFn: (a: CreatorSummary, b: CreatorSummary) => b.rating - a.rating,
    },
    {
      value: "followers",
      label: "Most Followers",
      sortFn: (a: CreatorSummary, b: CreatorSummary) => {
        const aFollowers = Object.values(a.socialMedia).reduce(
          (sum, val) => sum + (val || 0),
          0
        );
        const bFollowers = Object.values(b.socialMedia).reduce(
          (sum, val) => sum + (val || 0),
          0
        );
        return bFollowers - aFollowers;
      },
    },
    {
      value: "gmv",
      label: "Highest Monthly GMV",
      sortFn: (a: CreatorSummary, b: CreatorSummary) =>
        (b.monthlyGMV || 0) - (a.monthlyGMV || 0),
    },
    {
      value: "name",
      label: "Name (A-Z)",
      sortFn: (a: CreatorSummary, b: CreatorSummary) =>
        a.name.localeCompare(b.name),
    },
  ];

  return (
    <Box>
      <Container size="xl">
        <Stack gap={"xl"}>
          <Box ta="center">
            <Title
              order={1}
              mb="md"
              display={"inline-block"}
              style={(theme) => ({
                background: `linear-gradient(45deg, ${theme.colors.brandOrange[6]}, ${theme.colors.brandYellow[6]}, ${theme.colors.brandGreen[6]}, ${theme.colors.brandPurple[6]})`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              })}
            >
              Discover Creators
            </Title>
            <Text size="xl" c="dimmed">
              Find the perfect creator to promote your brand
            </Text>
          </Box>

          <FilterPanel
            filterOptions={filterOptions}
            sortOptions={sortOptions}
            sortValue={sortBy}
            onSortChange={setSortBy}
            onClearFilters={handleClearFilters}
            expandable={true}
            defaultExpanded={false}
            data={creators}
            onFilteredDataChange={setFilteredCreators}
          />

          {loading ? (
            <Center h={300}>
              <Loader size="xl" color="orange" />
            </Center>
          ) : filteredCreators.length === 0 ? (
            <Center p="xl">
              <Text size="lg">
                No creators found matching your search criteria.
              </Text>
            </Center>
          ) : (
            <Grid>
              {filteredCreators.map((creator) => (
                <Grid.Col key={creator.id} span={{ base: 12, sm: 6, lg: 4 }}>
                  <CreatorCard creator={creator} />
                </Grid.Col>
              ))}
            </Grid>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default DiscoverCreatorsPage;
