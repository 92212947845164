import {
  ActionRequiredBy,
  Collab,
  CollabStatus,
} from "../../pages/CollabsPage/Collabs.types";
import { mockCreators } from "./creatorsMockData";

export const mockCollabs: Collab[] = [
  {
    id: "1",
    brand: {
      id: "b1",
      name: "EcoBeauty",
      logo: "https://placehold.co/200x200?text=EcoBeauty",
      description: "Sustainable beauty products",
    },
    creator: mockCreators[0],
    product: {
      id: "p1",
      name: "Organic Face Serum",
      image: "https://placehold.co/300x300?text=Serum",
      description: "All-natural face serum with vitamin C",
      price: 29.99,
      categories: ["Skin Care"],
    },
    price: 1500,
    requirements: [
      {
        type: "video",
        quantity: 2,
        details: "30-second product review videos",
      },
      {
        type: "post",
        quantity: 3,
        details: "Instagram posts featuring the product",
      },
    ],
    status: CollabStatus.Pending,
    actionRequiredBy: ActionRequiredBy.Creator,
    createdAt: new Date("2023-10-01"),
    updatedAt: new Date("2023-10-01"),
    messages: 2,
    unreadMessages: 1,
  },
  {
    id: "2",
    brand: {
      id: "b2",
      name: "FitLife",
      logo: "https://placehold.co/200x200?text=FitLife",
      description: "Fitness and wellness products",
    },
    creator: mockCreators[1],
    product: {
      id: "p2",
      name: "Premium Protein Powder",
      image: "https://placehold.co/300x300?text=Protein",
      description: "Plant-based protein powder",
      price: 39.99,
      categories: ["Fitness"],
    },
    price: 2000,
    requirements: [
      {
        type: "video",
        quantity: 1,
        details: "Workout video featuring the product",
      },
      {
        type: "livestream",
        quantity: 1,
        details: "1-hour workout session using the product",
      },
    ],
    status: CollabStatus.Accepted,
    actionRequiredBy: ActionRequiredBy.Brand,
    createdAt: new Date("2023-09-15"),
    updatedAt: new Date("2023-09-20"),
    messages: 5,
    unreadMessages: 0,
  },
  {
    id: "3",
    brand: {
      id: "b3",
      name: "TechGadgets",
      logo: "https://placehold.co/200x200?text=TechGadgets",
      description: "Innovative tech products",
    },
    creator: mockCreators[2],
    product: {
      id: "p3",
      name: "Smart Home Hub",
      image: "https://placehold.co/300x300?text=SmartHub",
      description: "All-in-one smart home controller",
      price: 129.99,
      categories: ["Tech"],
    },
    price: 3000,
    requirements: [
      {
        type: "video",
        quantity: 1,
        details: "In-depth product review video",
      },
      {
        type: "post",
        quantity: 2,
        details: "Social media posts about the product features",
      },
    ],
    status: CollabStatus.Discussing,
    actionRequiredBy: ActionRequiredBy.Creator,
    createdAt: new Date("2023-09-10"),
    updatedAt: new Date("2023-09-25"),
    messages: 8,
    unreadMessages: 2,
  },
  {
    id: "4",
    brand: {
      id: "b4",
      name: "FashionForward",
      logo: "https://placehold.co/200x200?text=Fashion",
      description: "Trendy fashion brand",
    },
    creator: mockCreators[3],
    product: {
      id: "p4",
      name: "Designer Handbag",
      image: "https://placehold.co/300x300?text=Handbag",
      description: "Limited edition designer handbag",
      price: 249.99,
      categories: ["Fashion"],
    },
    price: 2500,
    requirements: [
      {
        type: "post",
        quantity: 5,
        details: "Fashion posts featuring the handbag",
      },
      {
        type: "story",
        quantity: 3,
        details: "Instagram stories showcasing the product",
      },
    ],
    status: CollabStatus.Completed,
    actionRequiredBy: ActionRequiredBy.None,
    createdAt: new Date("2023-08-01"),
    updatedAt: new Date("2023-09-01"),
    messages: 12,
    unreadMessages: 0,
  },
  {
    id: "5",
    brand: {
      id: "b5",
      name: "GourmetEats",
      logo: "https://placehold.co/200x200?text=GourmetEats",
      description: "Premium food products",
    },
    creator: mockCreators[4],
    product: {
      id: "p5",
      name: "Gourmet Cooking Set",
      image: "https://placehold.co/300x300?text=CookingSet",
      description: "Professional-grade cooking utensils",
      price: 179.99,
      categories: ["Food"],
    },
    price: 1800,
    requirements: [
      {
        type: "video",
        quantity: 3,
        details: "Recipe videos using the cooking set",
      },
      {
        type: "livestream",
        quantity: 1,
        details: "Live cooking demonstration",
      },
    ],
    status: CollabStatus.Declined,
    actionRequiredBy: ActionRequiredBy.None,
    createdAt: new Date("2023-09-05"),
    updatedAt: new Date("2023-09-10"),
    messages: 3,
    unreadMessages: 0,
  },
  {
    id: "6",
    brand: {
      id: "b1",
      name: "EcoBeauty",
      logo: "https://placehold.co/200x200?text=EcoBeauty",
      description: "Sustainable beauty products",
    },
    creator: mockCreators[5],
    product: {
      id: "p6",
      name: "Natural Skincare Bundle",
      image: "https://placehold.co/300x300?text=SkinBundle",
      description: "Complete natural skincare set",
      price: 89.99,
      categories: ["Beauty"],
    },
    price: 1200,
    requirements: [
      {
        type: "video",
        quantity: 1,
        details: "Skincare routine video",
      },
      {
        type: "post",
        quantity: 4,
        details: "Before/after posts showing results",
      },
    ],
    status: CollabStatus.Pending,
    actionRequiredBy: ActionRequiredBy.Brand,
    createdAt: new Date("2023-09-28"),
    updatedAt: new Date("2023-09-28"),
    messages: 0,
    unreadMessages: 0,
  },
];
