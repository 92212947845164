import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Card,
  Divider,
  Flex,
  Group,
  Rating,
  SimpleGrid,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import {
  IconHandGrab,
  // IconHandshake,
  IconPackage,
  IconTag,
} from "@tabler/icons-react";

export interface Brand {
  id: string;
  name: string;
  logo: string;
  rating: number;
  description: string;
  productsCount: number;
  completedCollabs: number;
  categories: string[];
}

interface BrandCardProps {
  brand: Brand;
}

export function BrandCard({ brand }: BrandCardProps) {
  const theme = useMantineTheme();

  // Format numbers (e.g., 1500 -> 1.5K)
  const formatNumber = (count: number): string => {
    if (count >= 1000000) {
      return `${(count / 1000000).toFixed(1)}M`;
    }
    if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}K`;
    }
    return count.toString();
  };

  // Format currency (e.g., 15000 -> $15,000)
  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(amount);
  };

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Flex
        direction={{ base: "column", sm: "row" }}
        gap="md"
        align={{ base: "center", sm: "flex-start" }}
      >
        {/* Logo */}
        <Avatar
          src={brand.logo}
          size={80}
          radius={80}
          alt={`${brand.name}'s logo`}
        />

        {/* Brand info */}
        <Stack gap="xs" style={{ flex: 1 }}>
          <Group justify="space-between" wrap="nowrap">
            <Box>
              <Text fw={700} size="xl">
                {brand.name}
              </Text>
              <Group gap="xs">
                <Rating value={brand.rating} readOnly fractions={2} />
                <Text size="sm" c="dimmed">
                  ({brand.rating.toFixed(1)})
                </Text>
              </Group>
            </Box>

            {/* Products count badge - visible on mobile and desktop */}
            <Badge
              size="lg"
              variant="light"
              leftSection={<IconPackage size={14} />}
              styles={{
                root: {
                  whiteSpace: "nowrap",
                  minWidth: "fit-content",
                  overflow: "visible",
                },
                label: {
                  overflow: "visible",
                  textOverflow: "clip",
                },
              }}
            >
              {formatNumber(brand.productsCount)} Products
            </Badge>
          </Group>

          <Text size="sm" lineClamp={3}>
            {brand.description}
          </Text>
        </Stack>
      </Flex>

      {/* Categories and Monthly GMV */}
      <SimpleGrid cols={2} mt="md">
        <Group gap="xs">
          {brand.categories.map((category) => (
            <Badge
              key={category}
              variant="outline"
              size="sm"
              leftSection={<IconTag size={12} />}
            >
              {category}
            </Badge>
          ))}
        </Group>
      </SimpleGrid>

      <Divider my="md" />

      {/* Completed Collaborations */}
      <Flex gap="md" wrap="wrap" justify={{ base: "center", sm: "flex-start" }}>
        <Group gap="xs">
          <ActionIcon
            variant="light"
            color={theme.colors.blue[6]}
            size="lg"
            radius="xl"
          >
            <IconHandGrab size={18} />
          </ActionIcon>
          <Stack gap={0}>
            <Text size="xs" tt="uppercase" fw={500}>
              Collabs
            </Text>
            <Text size="sm" fw={700}>
              {formatNumber(brand.completedCollabs)}
            </Text>
          </Stack>
        </Group>
      </Flex>
    </Card>
  );
}

export default function BrandCardExample() {
  const brandData: Brand = {
    id: "brand-123",
    name: "EcoBeauty",
    logo: "https://placehold.co/200x200?text=EcoBeauty",
    rating: 4.8,
    description:
      "Sustainable beauty products made with organic ingredients. Cruelty-free and environmentally conscious.",
    productsCount: 24,
    completedCollabs: 47,
    categories: ["Beauty", "Organic", "Skincare"],
  };

  return (
    <Box p="md" maw={500} mx="auto">
      <BrandCard brand={brandData} />
    </Box>
  );
}
