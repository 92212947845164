import { useEffect, useState } from "react";
import { UserRole } from "../../CollabsPage/CollabsPage";
import { BrandProfile, CreatorProfile, Profile } from "../Profile.types";

// Mock data for brand profile
const mockBrandProfile: BrandProfile = {
  id: "b1",
  name: "EcoStyle",
  logo: "https://images.unsplash.com/photo-1560179707-f14e90ef3623?q=80&w=200&h=200&auto=format&fit=crop",
  categories: ["fashion", "lifestyle", "sustainability"],
  location: "San Francisco, CA",
  description:
    "EcoStyle is a sustainable fashion brand committed to creating stylish, eco-friendly clothing using recycled materials and ethical manufacturing processes.",
  email: "contact@ecostyle.com",
  phone: "+1 (415) 555-1234",
  website: "https://ecostyle.example.com",
  foundedYear: "2018",
  companySize: "10-50 employees",
  brandStory:
    "Founded in 2018 by environmental activists, EcoStyle was born from a desire to prove that fashion can be both stylish and sustainable. We source recycled materials and ensure fair wages for all workers in our supply chain.",
  rating: 4.7,
  reviewCount: 42,
  reviews: [
    {
      name: "Alex Johnson",
      avatarUrl:
        "https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=100&h=100&auto=format&fit=crop",
      rating: 5,
      date: "2023-10-15",
      comment:
        "Amazing brand with truly sustainable practices. The quality of their clothing is exceptional and lasts for years!",
    },
    {
      name: "Jamie Smith",
      avatarUrl:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&auto=format&fit=crop",
      rating: 4.5,
      date: "2023-09-22",
      comment:
        "Love their commitment to the environment. Their customer service is also top-notch.",
    },
    {
      name: "Taylor Reed",
      avatarUrl:
        "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=100&h=100&auto=format&fit=crop",
      rating: 4.5,
      date: "2023-08-03",
      comment:
        "Great products, though shipping could be faster. Still, worth the wait for sustainable fashion.",
    },
  ],
  products: [
    {
      id: "p1",
      name: "Recycled Denim Jacket",
      price: 89.99,
      imageUrl:
        "https://images.unsplash.com/photo-1576995853123-5a10305d93c0?w=300&h=300&auto=format&fit=crop",
    },
    {
      id: "p2",
      name: "Organic Cotton Tee",
      price: 34.99,
      imageUrl:
        "https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?w=300&h=300&auto=format&fit=crop",
    },
    {
      id: "p3",
      name: "Bamboo Lounge Pants",
      price: 59.99,
      imageUrl:
        "https://images.unsplash.com/photo-1624378439575-d8705ad7ae80?w=300&h=300&auto=format&fit=crop",
    },
    {
      id: "p4",
      name: "Hemp Canvas Backpack",
      price: 79.99,
      imageUrl:
        "https://images.unsplash.com/photo-1553062407-98eeb64c6a62?w=300&h=300&auto=format&fit=crop",
    },
  ],
};

// Mock data for creator profile
const mockCreatorProfile: CreatorProfile = {
  id: "c1",
  name: "Maya Chen",
  logo: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=200&h=200&auto=format&fit=crop",
  categories: ["fashion", "lifestyle", "travel"],
  location: "Los Angeles, CA",
  description:
    "Lifestyle content creator specializing in sustainable fashion, mindful living, and eco-friendly travel guides.",
  email: "maya@creatormail.com",
  phone: "+1 (323) 555-6789",
  website: "https://mayachen.creator.com",
  contentStyle:
    "Bright, minimalist aesthetic with a focus on storytelling through visuals. I create authentic content that resonates with conscious consumers.",
  audienceDemographics:
    "Primarily women aged 25-34 interested in sustainable living, ethical fashion, and mindful travel. 70% US-based, 30% international.",
  monthlyGMV: 12500,
  engagementRate: 4.8,
  rating: 4.9,
  reviewCount: 37,
  completedCollabs: 24,
  socialMedia: {
    instagram: 85000,
    tiktok: 120000,
    youtube: 45000,
    twitter: 30000,
  },
  reviews: [
    {
      name: "EcoStyle",
      avatarUrl:
        "https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=100&h=100&auto=format&fit=crop",
      rating: 5,
      date: "2023-11-05",
      comment:
        "Maya created exceptional content for our sustainable collection. Her authentic approach resonated perfectly with our audience and drove significant engagement.",
    },
    {
      name: "GreenLife",
      avatarUrl:
        "https://images.unsplash.com/photo-1549298916-b41d501d3772?w=100&h=100&auto=format&fit=crop",
      rating: 5,
      date: "2023-10-12",
      comment:
        "Working with Maya was a dream. She understood our brand values and created content that perfectly aligned with our mission.",
    },
    {
      name: "TravelEco",
      avatarUrl:
        "https://images.unsplash.com/photo-1568822617270-2c1579f8dfe2?w=100&h=100&auto=format&fit=crop",
      rating: 4.5,
      date: "2023-09-18",
      comment:
        "Maya's travel content for our eco-resorts was beautiful and engaging. Her audience was highly responsive to our collaboration.",
    },
  ],
  portfolio: [
    {
      id: "port1",
      title: "Sustainable Summer Collection",
      type: "Fashion Campaign",
      description:
        "Collaboration with EcoStyle featuring their recycled denim collection",
      imageUrl:
        "https://images.unsplash.com/photo-1515886657613-9f3515b0c78f?w=300&h=300&auto=format&fit=crop",
    },
    {
      id: "port2",
      title: "Eco Travel Guide: Costa Rica",
      type: "Travel Content",
      description:
        "Sustainable travel guide highlighting eco-friendly resorts and activities",
      imageUrl:
        "https://images.unsplash.com/photo-1605497788044-5a32c7078486?w=300&h=300&auto=format&fit=crop",
    },
    {
      id: "port3",
      title: "Zero Waste Home Series",
      type: "Lifestyle Content",
      description:
        "Educational content series on reducing waste in everyday life",
      imageUrl:
        "https://images.unsplash.com/photo-1556228578-0d85b1a4d571?w=300&h=300&auto=format&fit=crop",
    },
    {
      id: "port4",
      title: "Capsule Wardrobe Challenge",
      type: "Fashion Series",
      description:
        "30-day challenge showing how to style a minimal, sustainable wardrobe",
      imageUrl:
        "https://images.unsplash.com/photo-1551232864-3f0890e580d9?w=300&h=300&auto=format&fit=crop",
    },
  ],
};

export const useProfile = (role: UserRole) => {
  const [profile, setProfile] = useState<Profile>(
    role === UserRole.Brand ? mockBrandProfile : mockCreatorProfile
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    // Simulate API call
    setTimeout(() => {
      setProfile(
        role === UserRole.Brand ? mockBrandProfile : mockCreatorProfile
      );
      setLoading(false);
    }, 500);
  }, [role]);

  const updateProfile = (updatedProfile: Profile) => {
    setLoading(true);
    // Simulate API call
    setTimeout(() => {
      setProfile(updatedProfile);
      setLoading(false);
    }, 500);
  };

  return {
    profile,
    loading,
    updateProfile,
  };
};
