import { Box } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { AnimatePresence, motion } from "framer-motion";
import { ReactNode } from "react";
import { getBrandColor } from "../../mantineTheme";

interface AnimationOverlayProps {
  type: "success" | "error" | "custom";
  isVisible: boolean;
  customIcon?: ReactNode;
  duration?: number;
  backgroundColor?: string;
  zIndex?: number;
}

const AnimationOverlay = ({
  type,
  isVisible,
  customIcon,
  duration = 2,
  backgroundColor = "rgba(255, 255, 255, 0.8)",
  zIndex = 10,
}: AnimationOverlayProps) => {
  const getIcon = () => {
    switch (type) {
      case "success":
        return (
          <IconCheck size={80} color={getBrandColor("green")} stroke={2} />
        );
      case "error":
        return <IconX size={80} color="red" stroke={2} />;
      case "custom":
        return customIcon;
      default:
        return null;
    }
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <Box
          component={motion.div}
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1.2 }}
          exit={{ opacity: 0, scale: 1 }}
          transition={{ duration: duration / 2 }}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor,
            zIndex,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {getIcon()}
        </Box>
      )}
    </AnimatePresence>
  );
};

export default AnimationOverlay;
