import { Box, Container, Stack, Tabs, Text, Title } from "@mantine/core";
import { useState } from "react";
import classes from "./CollabsPage.module.css";
import CollabsList from "./components/CollabsList";
import RoleToggle from "./components/RoleToggle";
import { useCollabs } from "./useCollabs";

export enum UserRole {
  Brand = "brand",
  Creator = "creator",
}

const CollabsPage = () => {
  const [activeRole, setActiveRole] = useState<UserRole>(UserRole.Brand);
  const {
    pendingCollabs,
    acceptedCollabs,
    discussingCollabs,
    historyCollabs,
    actionRequiredCounts,
    updateCollab,
    loading,
  } = useCollabs(activeRole);

  return (
    <Box>
      <Container size="xl">
        <Stack gap="xl">
          <Box ta="center">
            <Title
              order={1}
              mb="md"
              display="inline-block"
              style={(theme) => ({
                background: `linear-gradient(45deg, ${theme.colors.brandOrange[6]}, ${theme.colors.brandYellow[6]}, ${theme.colors.brandGreen[6]}, ${theme.colors.brandPurple[6]})`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              })}
            >
              Collabs
            </Title>
            <Text size="xl" c="dimmed">
              Manage your collabs.
            </Text>
          </Box>

          <RoleToggle activeRole={activeRole} onChange={setActiveRole} />

          <Tabs defaultValue="pending" variant="outline">
            <Tabs.List grow>
              <Tabs.Tab
                value="pending"
                rightSection={
                  actionRequiredCounts.pending > 0 ? (
                    <Box className={classes.notificationBadge}>
                      {actionRequiredCounts.pending}
                    </Box>
                  ) : null
                }
              >
                Pending
              </Tabs.Tab>
              <Tabs.Tab
                value="accepted"
                rightSection={
                  actionRequiredCounts.accepted > 0 ? (
                    <Box className={classes.notificationBadge}>
                      {actionRequiredCounts.accepted}
                    </Box>
                  ) : null
                }
              >
                Accepted
              </Tabs.Tab>
              <Tabs.Tab
                value="discussing"
                rightSection={
                  actionRequiredCounts.discussing > 0 ? (
                    <Box className={classes.notificationBadge}>
                      {actionRequiredCounts.discussing}
                    </Box>
                  ) : null
                }
              >
                Discussing
              </Tabs.Tab>
              <Tabs.Tab
                value="history"
                rightSection={
                  actionRequiredCounts.history > 0 ? (
                    <Box className={classes.notificationBadge}>
                      {actionRequiredCounts.history}
                    </Box>
                  ) : null
                }
              >
                History
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="pending" pt="xl">
              <CollabsList
                collabs={pendingCollabs}
                role={activeRole}
                onUpdateCollab={updateCollab}
              />
            </Tabs.Panel>

            <Tabs.Panel value="accepted" pt="xl">
              <CollabsList
                collabs={acceptedCollabs}
                role={activeRole}
                onUpdateCollab={updateCollab}
              />
            </Tabs.Panel>

            <Tabs.Panel value="discussing" pt="xl">
              <CollabsList
                collabs={discussingCollabs}
                role={activeRole}
                onUpdateCollab={updateCollab}
              />
            </Tabs.Panel>

            <Tabs.Panel value="history" pt="xl">
              <CollabsList
                collabs={historyCollabs}
                role={activeRole}
                onUpdateCollab={updateCollab}
              />
            </Tabs.Panel>
          </Tabs>
        </Stack>
      </Container>
    </Box>
  );
};

export default CollabsPage;
