import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Box, MantineProvider, Stack } from "@mantine/core";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import "./App.scss";
import { isLoggedInAtom, userInfoAtom } from "./atoms";
import Footer from "./components/Footer";
import { environment } from "./constants/environment";
import DiscoverCreatorsPage from "./pages/DiscoverPage/DiscoverPage";
import { decodeJWT, isLoggedIn } from "./utils/jwtUtils";

import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import BrandCardExample from "./components/Cards/BrandCard";
import CreatorCardExample from "./components/Cards/CreatorCard";
import BrandNavBar from "./components/NavBar";
import { mantineTheme } from "./mantineTheme";
import NotFoundPage from "./pages/404NotFoundPageTODO/NotFoundPage";
import CollabsPage from "./pages/CollabsPage/CollabsPage";
import { default as RoleSelectionPage } from "./pages/LandingPage/LandingPage";
import ProductsPage from "./pages/ProductsPage/ProductsPage";
import ProfilePage from "./pages/ProfilePage.tsx/ProfilePage";
// import "@mantine/form/styles.css";
// import "@mantine/modals/styles.css";

const client = new ApolloClient({
  uri: `${environment.BACKEND_URL}/graphql`,
  cache: new InMemoryCache(),
  // credentials: "include",
  headers: {
    Authorization: Cookies.get("jwt") ?? "",
  },
});

function App() {
  // Recoil State
  const setIsLoggedIn = useSetRecoilState(isLoggedInAtom);
  const setUserInfo = useSetRecoilState(userInfoAtom);

  useEffect(() => {
    if (isLoggedIn()) {
      setIsLoggedIn(true);
      setUserInfo(decodeJWT());
    } else {
      setIsLoggedIn(false);
      setUserInfo(null);
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <MantineProvider theme={mantineTheme}>
        <BrowserRouter>
          <Stack mih="100vh" gap={"md"} bg={"gray.0"}>
            {/* <NavBar /> */}
            <BrandNavBar />

            <Box component="main" pt="80px" pb={"md"}>
              <Routes>
                <Route index element={<RoleSelectionPage />} />
                <Route path="/discover" element={<DiscoverCreatorsPage />} />
                <Route path="/products" element={<ProductsPage />} />
                <Route path="/collabs" element={<CollabsPage />} />
                <Route path="/brand" element={<BrandCardExample />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/test" element={<CreatorCardExample />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Box>

            <Footer />
          </Stack>
        </BrowserRouter>
      </MantineProvider>
    </ApolloProvider>
  );
}

export default App;
