import { TextInput, TextInputProps } from "@mantine/core";
import { useEffect, useState } from "react";

// Helper function to format numbers with commas
const formatNumberWithCommas = (value: number | string): string => {
  if (value === "" || value === undefined) return "";
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// Helper function to parse formatted number string back to number
const parseFormattedNumber = (value: string): number => {
  return Number(value.replace(/,/g, ""));
};

interface FormattedNumberInputProps
  extends Omit<TextInputProps, "onChange" | "value"> {
  value: number | string;
  onChange: (value: number | "") => void;
  allowDecimals?: boolean;
  allowNegative?: boolean;
}

const FormattedNumberInput: React.FC<FormattedNumberInputProps> = ({
  value,
  onChange,
  allowDecimals = false,
  allowNegative = false,
  ...props
}) => {
  // Store the formatted display value
  const [displayValue, setDisplayValue] = useState("");

  // Update the display value when the actual value changes
  useEffect(() => {
    if (value === "" || value === undefined) {
      setDisplayValue("");
    } else {
      setDisplayValue(formatNumberWithCommas(value));
    }
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // If the input is empty, reset both values
    if (inputValue === "") {
      setDisplayValue("");
      onChange("");
      return;
    }

    // Remove any non-numeric characters except commas
    let regex = allowDecimals
      ? allowNegative
        ? /[^0-9,.-]/g
        : /[^0-9,.]/g
      : allowNegative
      ? /[^0-9,-]/g
      : /[^0-9,]/g;

    let cleanValue = inputValue.replace(regex, "");

    // Handle decimal points (if allowed)
    if (allowDecimals) {
      const decimalPoints = cleanValue.match(/\./g);
      if (decimalPoints && decimalPoints.length > 1) {
        // Keep only the first decimal point
        const parts = cleanValue.split(".");
        cleanValue = parts[0] + "." + parts.slice(1).join("");
      }
    }

    // Handle negative sign (if allowed)
    if (allowNegative) {
      const minusSigns = cleanValue.match(/-/g);
      if (minusSigns && minusSigns.length > 1) {
        // Keep only the first minus sign at the beginning
        cleanValue = cleanValue.replace(/-/g, "");
        if (inputValue.startsWith("-")) {
          cleanValue = "-" + cleanValue;
        }
      }
    }

    // Remove all commas for processing
    const numericValue = cleanValue.replace(/,/g, "");

    // Convert to number and update the actual value
    const numberValue =
      numericValue === ""
        ? ""
        : numericValue === "-"
        ? ""
        : Number(numericValue);

    // Only update if it's a valid number or empty string
    if (numberValue === "" || !isNaN(numberValue as number)) {
      onChange(numberValue);

      // Format the display value with commas
      if (numberValue !== "") {
        // For partial inputs like "1.", keep the decimal point
        if (allowDecimals && inputValue.endsWith(".")) {
          setDisplayValue(
            formatNumberWithCommas(numericValue.replace(".", "")) + "."
          );
        } else if (allowNegative && inputValue === "-") {
          setDisplayValue("-");
        } else {
          setDisplayValue(formatNumberWithCommas(numericValue));
        }
      } else {
        setDisplayValue("");
      }
    }
  };

  return (
    <TextInput {...props} value={displayValue} onChange={handleInputChange} />
  );
};

export default FormattedNumberInput;
