import {
  Accordion,
  Box,
  Button,
  Flex,
  MultiSelect,
  Paper,
  Select,
  Slider,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { IconFilter, IconSearch } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import FormattedNumberInput from "../FormattedNumberInput/FormattedNumberInput";

// Define specific filter option types for better type safety
export interface BaseFilterOption {
  id: string;
  label: string;
  placeholder?: string;
  filterFn?: (item: any, value: any) => boolean;
}

export interface SearchFilterOption extends BaseFilterOption {
  type: "search";
  value: string;
  onChange: (value: string) => void;
  searchFields?: string[]; // Fields to search in, e.g., ['name', 'description']
}

export interface SelectFilterOption extends BaseFilterOption {
  type: "select";
  data: { value: string; label: string }[];
  value: string;
  onChange: (value: string) => void;
  field?: string; // Field to filter on, e.g., 'status'
}

export interface MultiSelectFilterOption extends BaseFilterOption {
  type: "multiSelect";
  data: { value: string; label: string }[];
  value: string[];
  onChange: (value: string[]) => void;
  field?: string; // Field to filter on, e.g., 'categories'
}

export interface SliderFilterOption extends BaseFilterOption {
  type: "slider";
  min?: number;
  max?: number;
  step?: number;
  marks?: { value: number; label: string }[];
  value: number;
  onChange: (value: number) => void;
  field?: string; // Field to filter on, e.g., 'rating'
}

export interface NumberInputFilterOption extends BaseFilterOption {
  type: "numberInput";
  suffix?: string;
  value: number | "";
  onChange: (value: number | "") => void;
  field?: string; // Field to filter on, e.g., 'price'
  comparison?: "gt" | "lt" | "eq" | "gte" | "lte"; // Comparison type
}

export type FilterOption =
  | SearchFilterOption
  | SelectFilterOption
  | MultiSelectFilterOption
  | SliderFilterOption
  | NumberInputFilterOption;

export interface SortOption {
  value: string;
  label: string;
  sortFn?: (a: any, b: any) => number;
}

export interface FilterPanelProps<T> {
  title?: string;
  filterOptions: FilterOption[];
  sortOptions?: SortOption[];
  sortValue?: string;
  onSortChange?: (value: string) => void;
  onClearFilters?: () => void;
  expandable?: boolean;
  defaultExpanded?: boolean;
  data: T[];
  onFilteredDataChange: (filteredData: T[]) => void;
}

function FilterPanel<T>({
  title,
  filterOptions,
  sortOptions,
  sortValue,
  onSortChange,
  onClearFilters,
  expandable = false,
  defaultExpanded = false,
  data,
  onFilteredDataChange,
}: FilterPanelProps<T>) {
  const [showFilters, setShowFilters] = useState(defaultExpanded);
  const [filteredData, setFilteredData] = useState<T[]>(data);

  // Find the search filter if it exists
  const searchFilter = filterOptions.find(
    (option) => option.type === "search"
  ) as SearchFilterOption | undefined;

  // Get all other filters
  const advancedFilters = filterOptions.filter(
    (option) => option.type !== "search"
  );

  // Apply filters and sorting whenever filter values change
  useEffect(() => {
    let result = [...data];

    // Apply all filters
    filterOptions.forEach((filter) => {
      if (filter.filterFn) {
        // Use custom filter function if provided
        result = result.filter((item) =>
          filter.filterFn!(item, (filter as any).value)
        );
      } else {
        switch (filter.type) {
          case "search": {
            const searchValue = filter.value.toLowerCase();
            if (searchValue) {
              if (filter.searchFields && filter.searchFields.length > 0) {
                // Search in specified fields
                result = result.filter((item) =>
                  filter.searchFields!.some((field) => {
                    const fieldValue = getNestedValue(item, field);
                    return (
                      typeof fieldValue === "string" &&
                      fieldValue.toLowerCase().includes(searchValue)
                    );
                  })
                );
              } else {
                // Default search behavior - search in all string fields
                result = result.filter((item) => {
                  return Object.entries(item as any).some(
                    ([_, value]) =>
                      typeof value === "string" &&
                      value.toLowerCase().includes(searchValue)
                  );
                });
              }
            }
            break;
          }
          case "select": {
            if (filter.value && filter.field) {
              result = result.filter(
                (item) => getNestedValue(item, filter.field!) === filter.value
              );
            }
            break;
          }
          case "multiSelect": {
            if (filter.value.length > 0 && filter.field) {
              result = result.filter((item) => {
                const fieldValue = getNestedValue(item, filter.field!);
                if (Array.isArray(fieldValue)) {
                  // If the field is an array, check if any value matches
                  return fieldValue.some((val) => filter.value.includes(val));
                } else {
                  // If the field is a single value, check if it's in the selected values
                  return filter.value.includes(fieldValue);
                }
              });
            }
            break;
          }
          case "slider": {
            if (filter.field) {
              result = result.filter(
                (item) => getNestedValue(item, filter.field!) >= filter.value
              );
            }
            break;
          }
          case "numberInput": {
            if (filter.value !== "" && filter.field) {
              const comparison = filter.comparison || "gte";
              const numValue = filter.value;

              result = result.filter((item) => {
                const fieldValue = getNestedValue(item, filter.field!);
                switch (comparison) {
                  case "gt":
                    return fieldValue > numValue;
                  case "lt":
                    return fieldValue < numValue;
                  case "eq":
                    return fieldValue === numValue;
                  case "gte":
                    return fieldValue >= numValue;
                  case "lte":
                    return fieldValue <= numValue;
                  default:
                    return true;
                }
              });
            }
            break;
          }
        }
      }
    });

    // Apply sorting
    if (sortValue && sortOptions) {
      const selectedSort = sortOptions.find(
        (option) => option.value === sortValue
      );
      if (selectedSort?.sortFn) {
        // Use custom sort function if provided
        result.sort(selectedSort.sortFn);
      } else {
        // Default sorting by comparing values directly
        // This assumes the sortValue corresponds to a field name
        result.sort((a, b) => {
          const aValue = getNestedValue(a, sortValue);
          const bValue = getNestedValue(b, sortValue);

          if (typeof aValue === "string" && typeof bValue === "string") {
            return aValue.localeCompare(bValue);
          } else if (typeof aValue === "number" && typeof bValue === "number") {
            return aValue - bValue;
          }
          return 0;
        });
      }
    }

    setFilteredData(result);
    onFilteredDataChange(result);
  }, [data, filterOptions, sortValue, sortOptions]);

  // Helper function to get nested object values by dot notation
  // e.g., getNestedValue(obj, 'user.profile.name')
  const getNestedValue = (obj: any, path: string) => {
    return path.split(".").reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, obj);
  };

  const renderFilterInput = (filter: FilterOption) => {
    switch (filter.type) {
      case "search":
        return (
          <TextInput
            placeholder={filter.placeholder || "Search..."}
            value={filter.value}
            onChange={(e) => filter.onChange(e.currentTarget.value)}
            leftSection={<IconSearch size={16} />}
            style={{ flex: 3 }}
          />
        );
      case "select":
        return (
          <Select
            placeholder={filter.placeholder || "Select..."}
            value={filter.value}
            onChange={(value) => filter.onChange(value || "")}
            data={filter.data || []}
            clearable
          />
        );
      case "multiSelect":
        return (
          <MultiSelect
            placeholder={filter.placeholder || "Select multiple..."}
            value={filter.value}
            onChange={(value) => filter.onChange(value)}
            data={filter.data || []}
            clearable
            searchable
          />
        );
      case "slider":
        return (
          <>
            <Text fw={500} mb="xs">
              {filter.label}: {filter.value}
            </Text>
            <Slider
              value={filter.value}
              onChange={(value) => filter.onChange(value)}
              color="brandOrange"
              min={filter.min || 0}
              max={filter.max || 100}
              step={filter.step || 1}
              marks={filter.marks}
              style={(theme) => ({
                ".mantine-Slider-bar": {
                  backgroundColor: theme.colors.orange[6],
                },
                ".mantine-Slider-thumb": {
                  borderColor: theme.colors.gray[3],
                },
              })}
            />
          </>
        );
      case "numberInput":
        return (
          <FormattedNumberInput
            placeholder={filter.placeholder || "Enter number..."}
            value={filter.value}
            onChange={filter.onChange}
            rightSection={
              filter.suffix ? <Text size="sm">{filter.suffix}</Text> : undefined
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <Paper p="lg" radius="md" withBorder>
      {title && (
        <Text fw={600} size="lg" mb="md">
          {title}
        </Text>
      )}

      <Flex direction={{ base: "column", md: "row" }} gap="md" mb="md">
        {searchFilter && renderFilterInput(searchFilter)}

        {sortOptions && sortOptions.length > 0 && onSortChange && (
          <Select
            placeholder="Sort by"
            value={sortValue}
            onChange={(value) => onSortChange(value || sortOptions[0].value)}
            data={sortOptions}
            size="md"
            style={{ flex: 1 }}
          />
        )}
      </Flex>

      {advancedFilters.length > 0 &&
        (expandable ? (
          <Accordion>
            <Accordion.Item value="filters">
              <Accordion.Control
                onClick={() => setShowFilters(!showFilters)}
                style={(theme) => ({
                  color: theme.colors.brandGreen[6],
                  fontWeight: 500,
                  "&:hover": {
                    backgroundColor: theme.colors.gray[0],
                  },
                })}
              >
                {showFilters
                  ? "Hide Advanced Filters"
                  : "Show Advanced Filters"}
              </Accordion.Control>
              <Accordion.Panel>
                <AdvancedFiltersContent
                  filters={advancedFilters}
                  renderFilterInput={renderFilterInput}
                  onClearFilters={onClearFilters}
                />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        ) : (
          <AdvancedFiltersContent
            filters={advancedFilters}
            renderFilterInput={renderFilterInput}
            onClearFilters={onClearFilters}
          />
        ))}

      <Text size="sm" c="dimmed" mt="md">
        Showing {filteredData.length} of {data.length} items
      </Text>
    </Paper>
  );
}

interface AdvancedFiltersContentProps {
  filters: FilterOption[];
  renderFilterInput: (filter: FilterOption) => React.ReactNode;
  onClearFilters?: () => void;
}

const AdvancedFiltersContent: React.FC<AdvancedFiltersContentProps> = ({
  filters,
  renderFilterInput,
  onClearFilters,
}) => {
  // Group filters into rows of 3 for desktop layout
  const filterRows: FilterOption[][] = [];
  for (let i = 0; i < filters.length; i += 3) {
    filterRows.push(filters.slice(i, i + 3));
  }

  return (
    <Stack gap="md">
      {filterRows.map((row, rowIndex) => (
        <Flex
          key={`filter-row-${rowIndex}`}
          direction={{ base: "column", md: "row" }}
          gap="md"
        >
          {row.map((filter) => (
            <Box key={filter.id} style={{ flex: 1 }}>
              {filter.type !== "slider" && (
                <Text fw={500} mb="xs">
                  {filter.label}
                </Text>
              )}
              {renderFilterInput(filter)}
            </Box>
          ))}
        </Flex>
      ))}

      {onClearFilters && (
        <Button
          mt="sm"
          onClick={onClearFilters}
          color="brandGreen"
          size="sm"
          leftSection={<IconFilter size={16} />}
        >
          Clear All Filters
        </Button>
      )}
    </Stack>
  );
};

export default FilterPanel;
