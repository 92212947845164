import { CreatorSummary } from "../../components/Cards/CreatorCard";

export const mockCreators: CreatorSummary[] = [
  {
    id: "1",
    name: "Alex Johnson",
    image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330",
    rating: 4.8,
    description:
      "Lifestyle and fashion creator specializing in sustainable fashion and minimalist living. Known for authentic product reviews and engaging storytelling.",
    socialMedia: {},
    categories: ["Fashion", "Lifestyle"],
    monthlyGMV: 12000,
    completedCollabs: 45,
  },
  {
    id: "2",
    name: "Marcus Chen",
    image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d",
    rating: 4.5,
    description:
      "Tech reviewer and gadget enthusiast with a focus on honest, in-depth reviews of the latest consumer electronics and smart home devices.",
    socialMedia: {
      tiktok: 850000,
      instagram: 320000,
      twitter: 210000,
    },
    categories: ["Tech", "Gadgets"],
    monthlyGMV: 18000,
    completedCollabs: 38,
  },
  {
    id: "3",
    name: "Sophia Rodriguez",
    image: "https://images.unsplash.com/photo-1534528741775-53994a69daeb",
    rating: 4.9,
    description:
      "Beauty and skincare expert sharing tutorials, product reviews, and skincare routines. Known for educational content about ingredients and formulations.",
    socialMedia: {
      tiktok: 2100000,
    },
    categories: ["Beauty", "Skincare"],
    monthlyGMV: 16000,
    completedCollabs: 2000,
  },
  {
    id: "4",
    name: "Jordan Taylor",
    image: "https://images.unsplash.com/photo-1539571696357-5a69c17a67c6",
    rating: 4.2,
    description:
      "Fitness trainer and nutrition coach sharing workout routines, meal prep ideas, and supplement reviews. Specializes in home workouts and sustainable fitness.",
    socialMedia: {
      tiktok: 750000,
      twitter: 90000,
    },
    categories: ["Fitness", "Nutrition"],
    monthlyGMV: 14000,
    completedCollabs: 18,
  },
  {
    id: "5",
    name: "Emma Wilson",
    image: "https://images.unsplash.com/photo-1517841905240-472988babdf9",
    rating: 4.7,
    description:
      "Travel vlogger documenting adventures around the world with a focus on budget travel tips, hidden gems, and cultural experiences off the beaten path.",
    socialMedia: {
      tiktok: 1500000,
      instagram: 890000,
      youtube: 650000,
      twitter: 320000,
    },
    categories: ["Travel", "Lifestyle"],
    monthlyGMV: 13000,
    completedCollabs: 0,
  },
  {
    id: "6",
    name: "David Kim",
    image: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e",
    rating: 4.4,
    description:
      "Gaming content creator covering game reviews, live streams, and industry news. Known for in-depth analysis and entertaining commentary on the latest releases.",
    socialMedia: {
      tiktok: 950000,
      instagram: 280000,
      youtube: 1200000,
      twitter: 450000,
    },
    categories: ["Gaming", "Entertainment"],
    monthlyGMV: 11000,
    completedCollabs: 10,
  },
  {
    id: "7",
    name: "Olivia Martinez",
    image: "https://images.unsplash.com/photo-1531746020798-e6953c6e8e04",
    rating: 4.6,
    description:
      "Food creator sharing recipes, restaurant reviews, and cooking tutorials. Specializes in quick, healthy meals and international cuisine with a modern twist.",
    socialMedia: {
      tiktok: 1800000,
      instagram: 720000,
      youtube: 350000,
      twitter: 150000,
    },
    categories: ["Food", "Cooking"],
    monthlyGMV: 10000,
    completedCollabs: 0,
  },
];
