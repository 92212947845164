import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Group,
  Image,
  Paper,
  Rating,
  SimpleGrid,
  Stack,
  Switch,
  Tabs,
  Text,
  Title,
} from "@mantine/core";
import {
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandTwitter,
  IconBrandYoutube,
  IconCalendar,
  IconCash,
  IconEdit,
  IconHandGrab,
  IconMail,
  IconMapPin,
  IconPercentage,
  IconPhone,
  IconTag,
  IconUsers,
  IconWorld,
} from "@tabler/icons-react";
import { useState } from "react";
import { UserRole } from "../CollabsPage/CollabsPage";
import ProfileEditForm from "./components/ProfileEditForm";
import { useProfile } from "./components/useProfile";
import { isBrandProfile, isCreatorProfile } from "./Profile.types";
import classes from "./ProfilePage.module.css";

const ProfilePage = () => {
  const [isMyProfile, setIsMyProfile] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [activeRole, setActiveRole] = useState<UserRole>(UserRole.Brand);

  const { profile, loading, updateProfile } = useProfile(activeRole);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleProfileUpdate = (updatedProfile: any) => {
    updateProfile(updatedProfile);
    setIsEditing(false);
  };

  if (loading) {
    return (
      <Container size="xl">
        <Box ta="center" py="xl">
          <Text>Loading profile...</Text>
        </Box>
      </Container>
    );
  }

  return (
    <Box>
      <Container size="xl">
        <Stack gap="xl">
          {/* Toggle for demo purposes */}
          <Group justify="flex-end">
            <Switch
              label="View as my profile"
              checked={isMyProfile}
              onChange={(event) => setIsMyProfile(event.currentTarget.checked)}
            />
            <Switch
              label={activeRole === UserRole.Brand ? "Brand" : "Creator"}
              checked={activeRole === UserRole.Creator}
              onChange={(event) =>
                setActiveRole(
                  event.currentTarget.checked
                    ? UserRole.Creator
                    : UserRole.Brand
                )
              }
            />
          </Group>

          {isEditing ? (
            <ProfileEditForm
              profile={profile}
              onSave={handleProfileUpdate}
              onCancel={() => setIsEditing(false)}
              role={activeRole}
            />
          ) : (
            <>
              {/* Profile Header */}
              <Paper shadow="sm" p="md" radius="md" withBorder>
                <Group wrap="nowrap" align="flex-start">
                  <Avatar
                    src={profile.logo}
                    size={120}
                    radius={activeRole === UserRole.Brand ? "md" : "xl"}
                    className={classes.avatar}
                  />
                  <Box style={{ flex: 1 }}>
                    <Group justify="space-between" wrap="nowrap">
                      <Box>
                        <Title
                          order={1}
                          style={(theme) => ({
                            background: `linear-gradient(45deg, ${theme.colors.brandOrange[6]}, ${theme.colors.brandYellow[6]}, ${theme.colors.brandGreen[6]}, ${theme.colors.brandPurple[6]})`,
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                          })}
                        >
                          {profile.name}
                        </Title>
                        <Group gap="xs">
                          <Badge color="brandOrange" size="lg">
                            {activeRole === UserRole.Brand
                              ? "Brand"
                              : "Creator"}
                          </Badge>
                          {profile.categories?.map((category, index) => (
                            <Badge
                              key={index}
                              color="brandGreen"
                              size="lg"
                              leftSection={<IconTag size={14} />}
                            >
                              {category}
                            </Badge>
                          ))}
                          {profile.location && (
                            <Group gap="xs">
                              <IconMapPin size={16} />
                              <Text size="sm">{profile.location}</Text>
                            </Group>
                          )}
                        </Group>
                      </Box>
                      {isMyProfile && (
                        <Button
                          leftSection={<IconEdit size={16} />}
                          color="brandOrange"
                          onClick={handleEditToggle}
                        >
                          Edit Profile
                        </Button>
                      )}
                    </Group>

                    <Group mt="md" align="center">
                      <Rating value={profile.rating} readOnly fractions={2} />
                      <Text size="sm">({profile.reviewCount} reviews)</Text>
                    </Group>

                    {isCreatorProfile(profile) && profile.socialMedia && (
                      <Group mt="md">
                        {profile.socialMedia.instagram && (
                          <Group gap="xs">
                            <IconBrandInstagram size={20} color="#E1306C" />
                            <Text size="sm">
                              {profile.socialMedia.instagram.toLocaleString()}{" "}
                              followers
                            </Text>
                          </Group>
                        )}
                        {profile.socialMedia.tiktok && (
                          <Group gap="xs">
                            <IconBrandTiktok size={20} color="#000000" />
                            <Text size="sm">
                              {profile.socialMedia.tiktok.toLocaleString()}{" "}
                              followers
                            </Text>
                          </Group>
                        )}
                        {profile.socialMedia.youtube && (
                          <Group gap="xs">
                            <IconBrandYoutube size={20} color="#FF0000" />
                            <Text size="sm">
                              {profile.socialMedia.youtube.toLocaleString()}{" "}
                              subscribers
                            </Text>
                          </Group>
                        )}
                        {profile.socialMedia.twitter && (
                          <Group gap="xs">
                            <IconBrandTwitter size={20} color="#1DA1F2" />
                            <Text size="sm">
                              {profile.socialMedia.twitter.toLocaleString()}{" "}
                              followers
                            </Text>
                          </Group>
                        )}
                      </Group>
                    )}
                  </Box>
                </Group>
              </Paper>

              {/* Profile Tabs */}
              <Tabs defaultValue="about" variant="outline">
                <Tabs.List>
                  <Tabs.Tab value="about">About</Tabs.Tab>
                  <Tabs.Tab value="reviews">
                    Reviews ({profile.reviewCount})
                  </Tabs.Tab>
                  {isBrandProfile(profile) && (
                    <Tabs.Tab value="products">
                      Products ({profile.products?.length || 0})
                    </Tabs.Tab>
                  )}
                  {isCreatorProfile(profile) && (
                    <Tabs.Tab value="portfolio">
                      Portfolio ({profile.portfolio?.length || 0})
                    </Tabs.Tab>
                  )}
                </Tabs.List>

                {/* About Tab */}
                <Tabs.Panel value="about" pt="xl">
                  <Grid>
                    <Grid.Col span={{ base: 12, md: 8 }}>
                      <Stack>
                        <Paper shadow="xs" p="md" withBorder>
                          <Title order={3} mb="md">
                            Description
                          </Title>
                          <Text>{profile.description}</Text>
                        </Paper>

                        {isBrandProfile(profile) && profile.brandStory && (
                          <Paper shadow="xs" p="md" withBorder>
                            <Title order={3} mb="md">
                              Brand Story
                            </Title>
                            <Text>{profile.brandStory}</Text>
                          </Paper>
                        )}

                        {isCreatorProfile(profile) && (
                          <>
                            {profile.contentStyle && (
                              <Paper shadow="xs" p="md" withBorder>
                                <Title order={3} mb="md">
                                  Content Style
                                </Title>
                                <Text>{profile.contentStyle}</Text>
                              </Paper>
                            )}
                            {profile.audienceDemographics && (
                              <Paper shadow="xs" p="md" withBorder>
                                <Title order={3} mb="md">
                                  Audience Demographics
                                </Title>
                                <Text>{profile.audienceDemographics}</Text>
                              </Paper>
                            )}
                          </>
                        )}
                      </Stack>
                    </Grid.Col>

                    <Grid.Col span={{ base: 12, md: 4 }}>
                      <Stack>
                        <Paper shadow="xs" p="md" withBorder>
                          <Title order={3} mb="md">
                            Contact Information
                          </Title>
                          <Stack gap="sm">
                            {profile.email && (
                              <Group gap="xs">
                                <IconMail size={18} />
                                <Text>{profile.email}</Text>
                              </Group>
                            )}
                            {profile.phone && (
                              <Group gap="xs">
                                <IconPhone size={18} />
                                <Text>{profile.phone}</Text>
                              </Group>
                            )}
                            {profile.website && (
                              <Group gap="xs">
                                <IconWorld size={18} />
                                <Text
                                  component="a"
                                  href={profile.website}
                                  target="_blank"
                                  c="blue"
                                >
                                  {profile.website.replace(/^https?:\/\//, "")}
                                </Text>
                              </Group>
                            )}
                          </Stack>
                        </Paper>

                        {isBrandProfile(profile) && (
                          <Paper shadow="xs" p="md" withBorder>
                            <Title order={3} mb="md">
                              Brand Details
                            </Title>
                            <Stack gap="sm">
                              {profile.foundedYear && (
                                <Group gap="xs">
                                  <IconCalendar size={18} />
                                  <Text>Founded in {profile.foundedYear}</Text>
                                </Group>
                              )}
                              {profile.companySize && (
                                <Group gap="xs">
                                  <IconUsers size={18} />
                                  <Text>{profile.companySize}</Text>
                                </Group>
                              )}
                            </Stack>
                          </Paper>
                        )}

                        {isCreatorProfile(profile) && (
                          <Paper shadow="xs" p="md" withBorder>
                            <Title order={3} mb="md">
                              Creator Stats
                            </Title>
                            <Stack gap="sm">
                              {profile.monthlyGMV && (
                                <Group gap="xs">
                                  <IconCash size={18} />
                                  <Text>
                                    ${profile.monthlyGMV.toLocaleString()}/mo
                                    GMV
                                  </Text>
                                </Group>
                              )}
                              {profile.engagementRate && (
                                <Group gap="xs">
                                  <IconPercentage size={18} />
                                  <Text>
                                    {profile.engagementRate}% Engagement Rate
                                  </Text>
                                </Group>
                              )}
                              {profile.completedCollabs && (
                                <Group gap="xs">
                                  <IconHandGrab size={18} />
                                  <Text>
                                    {profile.completedCollabs} Completed Collabs
                                  </Text>
                                </Group>
                              )}
                            </Stack>
                          </Paper>
                        )}
                      </Stack>
                    </Grid.Col>
                  </Grid>
                </Tabs.Panel>

                {/* Reviews Tab */}
                <Tabs.Panel value="reviews" pt="xl">
                  <Stack>
                    {profile.reviews?.map((review, index) => (
                      <Paper key={index} shadow="xs" p="md" withBorder>
                        <Group align="flex-start" mb="md">
                          <Avatar src={review.avatarUrl} radius="xl" />
                          <Box style={{ flex: 1 }}>
                            <Group>
                              <Text fw={600}>{review.name}</Text>
                              <Text size="sm" c="dimmed">
                                {review.date}
                              </Text>
                            </Group>
                            <Rating value={review.rating} readOnly size="sm" />
                          </Box>
                        </Group>
                        <Text>{review.comment}</Text>
                      </Paper>
                    ))}
                  </Stack>
                </Tabs.Panel>

                {/* Products Tab (for Brands) */}
                {isBrandProfile(profile) && (
                  <Tabs.Panel value="products" pt="xl">
                    <SimpleGrid
                      cols={{ base: 1, sm: 2, md: 3, lg: 4 }}
                      spacing="md"
                    >
                      {profile.products?.map((product) => (
                        <Card
                          key={product.id}
                          shadow="sm"
                          padding="lg"
                          radius="md"
                          withBorder
                        >
                          <Card.Section>
                            <Image
                              src={product.imageUrl}
                              height={160}
                              alt={product.name}
                            />
                          </Card.Section>

                          <Stack mt="md" gap="xs">
                            <Text fw={500}>{product.name}</Text>
                            <Text size="xl" fw={700} c="brandGreen">
                              ${product.price.toFixed(2)}
                            </Text>
                            {product.description && (
                              <Text size="sm" c="dimmed" lineClamp={2}>
                                {product.description}
                              </Text>
                            )}
                          </Stack>

                          {product.link && (
                            <Button
                              variant="light"
                              color="brandOrange"
                              fullWidth
                              mt="md"
                              radius="md"
                              component="a"
                              href={product.link}
                              target="_blank"
                            >
                              View Product
                            </Button>
                          )}
                        </Card>
                      ))}
                    </SimpleGrid>
                  </Tabs.Panel>
                )}

                {/* Portfolio Tab (for Creators) */}
                {isCreatorProfile(profile) && (
                  <Tabs.Panel value="portfolio" pt="xl">
                    <SimpleGrid
                      cols={{ base: 1, sm: 2, md: 3, lg: 4 }}
                      spacing="md"
                    >
                      {profile.portfolio?.map((item) => (
                        <Card
                          key={item.id}
                          shadow="sm"
                          padding="lg"
                          radius="md"
                          withBorder
                        >
                          <Card.Section>
                            <Image
                              src={item.imageUrl}
                              height={160}
                              alt={item.title}
                            />
                          </Card.Section>

                          <Stack mt="md" gap="xs">
                            <Text fw={500}>{item.title}</Text>
                            <Badge color="brandPurple">{item.type}</Badge>
                            <Text size="sm" c="dimmed" lineClamp={3}>
                              {item.description}
                            </Text>
                          </Stack>

                          {item.link && (
                            <Button
                              variant="light"
                              color="brandOrange"
                              fullWidth
                              mt="md"
                              radius="md"
                              component="a"
                              href={item.link}
                              target="_blank"
                            >
                              View Project
                            </Button>
                          )}
                        </Card>
                      ))}
                    </SimpleGrid>
                  </Tabs.Panel>
                )}
              </Tabs>
            </>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default ProfilePage;
