import { createTheme } from "@mantine/core";

export const getBrandColor = (color: string) => {
  switch (color) {
    case "orange":
      return mantineTheme.colors!.brandOrange![6];
    case "orangeYellow":
      return mantineTheme.colors!.brandOrangeYellow![6];
    case "yellow":
      return mantineTheme.colors!.brandYellow![6];
    case "green":
      return mantineTheme.colors!.brandGreen![6];
    case "purple":
      return mantineTheme.colors!.brandPurple![6];
    default:
      return undefined;
  }
};

export const mantineTheme = createTheme({
  colors: {
    primary: [
      "#FFE8E0", // Lightest shade
      "#FFD4C6",
      "#FFC0AC",
      "#FFAC93",
      "#FF9779",
      "#FF8360",
      "#FF6B35", // Your original color
      "#E55A29",
      "#CC4A1E",
      "#B33A12", // Darkest shade
    ],
    // Each color needs 10 shades in Mantine
    brandOrange: [
      "#FFE8E0", // Lightest shade
      "#FFD4C6",
      "#FFC0AC",
      "#FFAC93",
      "#FF9779",
      "#FF8360",
      "#FF6B35", // Your original color
      "#E55A29",
      "#CC4A1E",
      "#B33A12", // Darkest shade
    ],
    brandOrangeYellow: [
      "#FFF6E0",
      "#FFF0CC",
      "#FFEAB8",
      "#FFE4A3",
      "#FFDE8F",
      "#FFD87A",
      "#FFC145", // Your original color
      "#E5AB39",
      "#CC962D",
      "#B38021",
    ],
    brandYellow: [
      "#FEF5ED",
      "#FDEADB",
      "#FCE0C9",
      "#FBD5B7",
      "#F9CBA5",
      "#F8C093",
      "#F7C59F", // Your original color
      "#DEAE8C",
      "#C59879",
      "#AC8166",
    ],
    brandGreen: [
      "#E0F2EC",
      "#C1E5D9",
      "#A2D8C6",
      "#83CBB3",
      "#64BEA0",
      "#45B18D",
      "#1A936F", // Your original color
      "#157F5F",
      "#106B4F",
      "#0B573F",
    ],
    brandPurple: [
      "#EAE6EC",
      "#D5CDD9",
      "#C0B4C6",
      "#ABA0B3",
      "#968BA0",
      "#81778D",
      "#6B4E71", // Your original color
      "#5C4361",
      "#4D3851",
      "#3E2D41",
    ],
  },
  // You can add other theme overrides here
  // fontFamily: 'Inter, sans-serif',
  // Example of other properties you might want to customize:
  // primaryColor: 'brandGreen',
  // defaultRadius: 'md',
});
