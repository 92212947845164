import { CreatorSummary } from "../../components/Cards/CreatorCard";

export enum CollabStatus {
  Pending = "pending",
  Accepted = "accepted",
  Discussing = "discussing",
  Completed = "completed",
  Declined = "declined",
}

export enum ActionRequiredBy {
  Brand = "brand",
  Creator = "creator",
  None = "none",
}

export interface Product {
  id: string;
  name: string;
  image: string;
  description: string;
  price: number;
  categories: string[];
  link?: string;
}

export interface BrandSummary {
  id: string;
  name: string;
  logo: string;
  description: string;
}

export interface CollabRequirement {
  type: "video" | "livestream" | "post" | "story" | "other";
  quantity: number;
  details: string;
}

export interface Collab {
  id: string;
  brand: BrandSummary;
  creator: CreatorSummary;
  product: Product;
  price: number;
  requirements: CollabRequirement[];
  status: CollabStatus;
  actionRequiredBy: ActionRequiredBy;
  createdAt: Date;
  updatedAt: Date;
  messages: number;
  unreadMessages: number;
}
