import {
  Box,
  Button,
  Group,
  Image,
  MultiSelect,
  NumberInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { PRODUCT_CATEGORIES } from "../../../constants/webData";
import { Product } from "../../CollabsPage/Collabs.types";

// URL validation regex pattern
const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

// Price validation regex pattern for dollars and cents (e.g., 10.99)
const pricePattern = /^\d+(\.\d{1,2})?$/;

export interface ProductFormValues {
  name: string;
  description: string;
  image: string;
  price: number;
  categories: string[];
  link: string;
}

interface ProductFormProps {
  initialValues?: Product;
  onSubmit: (values: ProductFormValues) => void;
  onCancel: () => void;
  submitLabel?: string;
}

function ProductForm({
  initialValues,
  onSubmit,
  onCancel,
  submitLabel = "Add Product",
}: ProductFormProps) {
  const form = useForm<ProductFormValues>({
    initialValues: {
      name: "",
      description: "",
      image: "",
      price: "" as unknown as number,
      categories: [],
      link: "",
    },
    validate: {
      name: (value) =>
        value.trim().length < 3 ? "Name must be at least 3 characters" : null,
      description: (value) =>
        value.trim().length < 10
          ? "Description must be at least 10 characters"
          : null,
      image: (value) => {
        if (value.trim().length === 0) return "Image URL is required";
        if (!urlPattern.test(value)) return "Please enter a valid URL";
        return null;
      },
      price: (value) => {
        if (!value || value === 0) return "Price is required";
        if (parseFloat(value.toString()) <= 0)
          return "Price must be greater than 0";
        const priceStr = value.toString();
        if (!pricePattern.test(priceStr))
          return "Please enter a valid price (e.g., 10.99)";
        return null;
      },
      categories: (value) =>
        value.length === 0
          ? "Select at least one category"
          : value.length > 3
          ? "Maximum 3 categories allowed"
          : null,
      link: (value) => {
        if (value.trim().length === 0) return "Product link is required";
        if (!urlPattern.test(value)) return "Please enter a valid URL";
        return null;
      },
    },
  });

  // Set initial values if editing an existing product
  useEffect(() => {
    if (initialValues) {
      form.setValues({
        name: initialValues.name,
        description: initialValues.description,
        image: initialValues.image,
        price: initialValues.price,
        categories: initialValues.categories,
        link: initialValues.link,
      });
    }
  }, [initialValues]);

  const handleSubmit = (values: ProductFormValues) => {
    onSubmit(values);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput
          label="Product Name"
          placeholder="Enter product name"
          required
          {...form.getInputProps("name")}
        />

        <TextInput
          label="Description"
          placeholder="Enter product description"
          required
          {...form.getInputProps("description")}
        />

        <TextInput
          label="Image URL"
          placeholder="Enter image URL (https://...)"
          required
          {...form.getInputProps("image")}
        />

        <NumberInput
          label="Price ($)"
          placeholder="Enter price (e.g., 19.99)"
          step={0.01}
          decimalScale={2}
          required
          hideControls
          {...form.getInputProps("price")}
        />

        <MultiSelect
          label="Categories"
          data={PRODUCT_CATEGORIES}
          placeholder="Select up to 3 categories"
          required
          {...form.getInputProps("categories")}
          onChange={(value) => {
            // Limit to 3 categories
            if (value.length <= 3) {
              form.setFieldValue("categories", value);
            } else {
              // If more than 3 are selected, keep only the first 3
              form.setFieldValue("categories", value.slice(0, 3));
            }
          }}
          error={form.errors.categories}
        />

        <TextInput
          label="Product Link"
          placeholder="Enter product URL (https://...)"
          required
          {...form.getInputProps("link")}
        />

        {form.values.image && (
          <Box mt="sm">
            <Text size="sm" fw={500} mb="xs">
              Image Preview:
            </Text>
            <Image
              src={form.values.image}
              alt="Product preview"
              height={200}
              fallbackSrc="https://placehold.co/400x200?text=Image+Preview"
            />
          </Box>
        )}

        <Group justify="flex-end" mt="md">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">{submitLabel}</Button>
        </Group>
      </Stack>
    </form>
  );
}

export default ProductForm;
