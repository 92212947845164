import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  Image,
  Stack,
  Text,
} from "@mantine/core";
import { IconCheck, IconMessage, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import AnimationOverlay from "../../../components/Animations/AcceptDeclineAnimation";
import MessageModal from "../../../components/Modals/MessageModal/MessageModal";
import { cardAnimationSeconds } from "../../../constants/webControls";
import { getBrandColor } from "../../../mantineTheme";
import { ActionRequiredBy, Collab, CollabStatus } from "../Collabs.types";
import { UserRole } from "../CollabsPage";

interface CollabCardProps {
  collab: Collab;
  userRole: UserRole;
  onMessage: (collabId: string) => void;
  onOpenConfirmation: (collab: Collab, action: "accept" | "decline") => void;
  animationAction?: "accept" | "decline" | null;
}

const CollabCard = ({
  collab,
  userRole,
  onMessage,
  onOpenConfirmation,
  animationAction,
}: CollabCardProps) => {
  const [showAnimation, setShowAnimation] = useState<
    "accept" | "decline" | null
  >(null);

  // Add state for message modal
  const [messageModalOpen, setMessageModalOpen] = useState(false);

  // Update animation state when animationAction prop changes
  useEffect(() => {
    if (animationAction) {
      setShowAnimation(animationAction);

      // Clear animation after it completes
      const timer = setTimeout(() => {
        setShowAnimation(null);
      }, cardAnimationSeconds * 1000);

      return () => clearTimeout(timer);
    }
  }, [animationAction]);

  const isActionRequired =
    (userRole === UserRole.Brand &&
      collab.actionRequiredBy === ActionRequiredBy.Brand) ||
    (userRole === UserRole.Creator &&
      collab.actionRequiredBy === ActionRequiredBy.Creator);

  const getStatusColor = (status: CollabStatus) => {
    switch (status) {
      case CollabStatus.Pending:
        return "yellow";
      case CollabStatus.Accepted:
        return "green";
      case CollabStatus.Discussing:
        return "blue";
      case CollabStatus.Completed:
        return "teal";
      case CollabStatus.Declined:
        return "red";
      default:
        return "gray";
    }
  };

  const formatRequirements = (collab: Collab) => {
    return collab.requirements
      .map((req) => `${req.quantity} ${req.type}${req.quantity > 1 ? "s" : ""}`)
      .join(", ");
  };

  // Handle message button click
  const handleMessageClick = () => {
    setMessageModalOpen(true);
    onMessage(collab.id);
  };

  return (
    <>
      <Card
        withBorder
        padding="md"
        radius="md"
        h="100%"
        display="flex"
        style={{
          flexDirection: "column",
          borderLeft: isActionRequired
            ? `4px solid ${getBrandColor("orange")}`
            : undefined,
          backgroundColor: isActionRequired ? "#fff9f5" : undefined,
          position: "relative",
          overflow: "hidden", // Ensure animation stays within card
        }}
      >
        {/* Animation overlay using the reusable component */}
        <AnimationOverlay
          type={showAnimation === "accept" ? "success" : "error"}
          isVisible={showAnimation !== null}
        />

        {isActionRequired && (
          <Badge
            color="brandOrange"
            variant="filled"
            size="sm"
            style={{ position: "absolute", top: 4, right: 10, zIndex: 2 }}
          >
            Action Required
          </Badge>
        )}

        <Stack gap="xs" style={{ flex: 1 }}>
          <Group justify="space-between" wrap="nowrap">
            <Text fw={700} size="md" lineClamp={1}>
              {collab.product.name}
            </Text>
            <Badge color={getStatusColor(collab.status)}>
              {collab.status.charAt(0).toUpperCase() + collab.status.slice(1)}
            </Badge>
          </Group>

          <Flex gap="md" align="flex-start">
            <Box style={{ width: 80, height: 80, flexShrink: 0 }}>
              <Image
                src={collab.product.image}
                alt={collab.product.name}
                radius="md"
                height={80}
                width={80}
                fit="cover"
              />
            </Box>

            <Stack gap="xs" style={{ flex: 1, minWidth: 0 }}>
              <Group gap="xs" wrap="nowrap">
                <Avatar src={collab.brand.logo} size="xs" radius="xl" />
                <Text size="sm" fw={500} lineClamp={1}>
                  {collab.brand.name}
                </Text>
              </Group>

              <Group gap="xs" wrap="nowrap">
                <Avatar src={collab.creator.image} size="xs" radius="xl" />
                <Text size="sm" fw={500} lineClamp={1}>
                  {collab.creator.name}
                </Text>
              </Group>

              <Group>
                <Text fw={600} size="md" c="brandGreen.6">
                  ${collab.price.toLocaleString()}
                </Text>
              </Group>

              <Text size="xs" c="dimmed" lineClamp={1}>
                for {formatRequirements(collab)}
              </Text>

              {collab.unreadMessages > 0 && (
                <Badge color="red" variant="light" size="sm">
                  {collab.unreadMessages} new message
                  {collab.unreadMessages > 1 ? "s" : ""}
                </Badge>
              )}
            </Stack>
          </Flex>
        </Stack>

        <Divider my="md" />

        <Group justify="flex-end" gap="sm">
          {collab.status === CollabStatus.Pending && (
            <>
              <Button
                variant="outline"
                color="red"
                size="xs"
                leftSection={<IconX size={14} />}
                onClick={() => onOpenConfirmation(collab, "decline")}
              >
                Decline
              </Button>
              <Button
                variant="filled"
                color="brandGreen"
                size="xs"
                leftSection={<IconCheck size={14} />}
                onClick={() => onOpenConfirmation(collab, "accept")}
              >
                Accept
              </Button>
            </>
          )}
          <Button
            variant={
              collab.status === CollabStatus.Pending ? "subtle" : "filled"
            }
            color="brandPurple"
            size="xs"
            leftSection={<IconMessage size={14} />}
            onClick={handleMessageClick}
          >
            {collab.messages > 0 ? `Messages (${collab.messages})` : "Message"}
          </Button>
        </Group>
      </Card>

      {/* Message Modal */}
      <MessageModal
        opened={messageModalOpen}
        onClose={() => setMessageModalOpen(false)}
        collab={collab}
        userRole={userRole}
      />
    </>
  );
};

export default CollabCard;
