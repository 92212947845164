import {
  Button,
  Divider,
  FileInput,
  Group,
  MultiSelect,
  NumberInput,
  Paper,
  SimpleGrid,
  Stack,
  TextInput,
  Textarea,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconUpload } from "@tabler/icons-react";
import { useState } from "react";
import { UserRole } from "../../CollabsPage/CollabsPage";
import { Profile, isBrandProfile, isCreatorProfile } from "../Profile.types";

interface ProfileEditFormProps {
  profile: Profile;
  onSave: (updatedProfile: Profile) => void;
  onCancel: () => void;
  role: UserRole;
}

const ProfileEditForm = ({
  profile,
  onSave,
  onCancel,
  role,
}: ProfileEditFormProps) => {
  const [logoFile, setLogoFile] = useState<File | null>(null);

  const form = useForm<Profile>({
    initialValues: { ...profile },
    validate: {
      name: (name) => (name ? null : "Name is required"),
      email: (email) =>
        !email || /^\S+@\S+$/.test(email) ? null : "Invalid email",
      description: (description) =>
        description ? null : "Description is required",
      categories: (categories) =>
        categories.length === 0
          ? "Select at least one category"
          : categories.length > 3
          ? "Maximum 3 categories allowed"
          : null,
    },
  });

  const handleSocialMediaChange = (platform: string, value: number | null) => {
    if (isCreatorProfile(form.values)) {
      form.setFieldValue(`socialMedia.${platform}`, value);
    }
  };

  const handleSubmit = (values: typeof form.values) => {
    // In a real app, you would handle file upload here
    onSave(values as Profile);
  };

  const categoryOptions = [
    { value: "fashion", label: "Fashion" },
    { value: "beauty", label: "Beauty" },
    { value: "fitness", label: "Fitness" },
    { value: "food", label: "Food & Beverage" },
    { value: "tech", label: "Technology" },
    { value: "lifestyle", label: "Lifestyle" },
    { value: "travel", label: "Travel" },
    { value: "gaming", label: "Gaming" },
    { value: "health", label: "Health & Wellness" },
    { value: "home", label: "Home & Decor" },
    { value: "sustainability", label: "Sustainability" },
  ];

  return (
    <Paper shadow="sm" p="lg" radius="md" withBorder>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap="xl">
          <Title order={2}>Edit Profile</Title>

          <SimpleGrid cols={{ base: 1, md: 2 }} spacing="md">
            <FileInput
              label="Logo/Profile Picture"
              placeholder="Upload new image"
              accept="image/*"
              leftSection={<IconUpload size={16} />}
              value={logoFile}
              onChange={setLogoFile}
            />

            <TextInput
              label="Name"
              placeholder="Your name or brand name"
              {...form.getInputProps("name")}
              required
            />
          </SimpleGrid>

          <MultiSelect
            label="Categories (up to 3)"
            placeholder="Select categories"
            data={categoryOptions}
            maxValues={3}
            {...form.getInputProps("categories")}
          />

          <TextInput
            label="Location"
            placeholder="City, Country"
            {...form.getInputProps("location")}
          />

          <Textarea
            label="Description"
            placeholder="Tell us about yourself or your brand"
            minRows={4}
            {...form.getInputProps("description")}
            required
          />

          <Divider label="Contact Information" labelPosition="center" />

          <SimpleGrid cols={{ base: 1, md: 2 }} spacing="md">
            <TextInput
              label="Email"
              placeholder="contact@example.com"
              {...form.getInputProps("email")}
            />

            <TextInput
              label="Phone"
              placeholder="+1 234 567 8900"
              {...form.getInputProps("phone")}
            />

            <TextInput
              label="Website"
              placeholder="https://example.com"
              {...form.getInputProps("website")}
            />
          </SimpleGrid>

          {role === UserRole.Brand && isBrandProfile(form.values) && (
            <>
              <Divider label="Brand Details" labelPosition="center" />

              <SimpleGrid cols={{ base: 1, md: 2 }} spacing="md">
                <TextInput
                  label="Founded Year"
                  placeholder="e.g. 2015"
                  {...form.getInputProps("foundedYear")}
                />

                <TextInput
                  label="Company Size"
                  placeholder="e.g. 10-50 employees"
                  {...form.getInputProps("companySize")}
                />
              </SimpleGrid>

              <Textarea
                label="Brand Story"
                placeholder="Share your brand's journey and mission"
                minRows={4}
                {...form.getInputProps("brandStory")}
              />
            </>
          )}

          {role === UserRole.Creator && isCreatorProfile(form.values) && (
            <>
              <Divider label="Creator Details" labelPosition="center" />

              <Textarea
                label="Content Style"
                placeholder="Describe your content style and niche"
                minRows={3}
                {...form.getInputProps("contentStyle")}
              />

              <Textarea
                label="Audience Demographics"
                placeholder="Describe your audience (age range, interests, etc.)"
                minRows={3}
                {...form.getInputProps("audienceDemographics")}
              />

              <NumberInput
                label="Monthly GMV ($)"
                placeholder="e.g. 5000"
                {...form.getInputProps("monthlyGMV")}
              />

              <NumberInput
                label="Engagement Rate (%)"
                placeholder="e.g. 3.5"
                {...form.getInputProps("engagementRate")}
                decimalScale={2}
                min={0}
                max={100}
              />

              <Divider label="Social Media Followers" labelPosition="center" />

              <SimpleGrid cols={{ base: 1, md: 2 }} spacing="md">
                <NumberInput
                  label="Instagram Followers"
                  placeholder="e.g. 10000"
                  value={form.values.socialMedia?.instagram || ""}
                  onChange={(value) =>
                    handleSocialMediaChange("instagram", value as number)
                  }
                />

                <NumberInput
                  label="TikTok Followers"
                  placeholder="e.g. 50000"
                  value={form.values.socialMedia?.tiktok || ""}
                  onChange={(value) =>
                    handleSocialMediaChange("tiktok", value as number)
                  }
                />

                <NumberInput
                  label="YouTube Subscribers"
                  placeholder="e.g. 25000"
                  value={form.values.socialMedia?.youtube || ""}
                  onChange={(value) =>
                    handleSocialMediaChange("youtube", value as number)
                  }
                />

                <NumberInput
                  label="Twitter Followers"
                  placeholder="e.g. 5000"
                  value={form.values.socialMedia?.twitter || ""}
                  onChange={(value) =>
                    handleSocialMediaChange("twitter", value as number)
                  }
                />
              </SimpleGrid>
            </>
          )}

          <Group justify="flex-end" mt="xl">
            <Button variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" color="brandGreen">
              Save Changes
            </Button>
          </Group>
        </Stack>
      </form>
    </Paper>
  );
};

export default ProfileEditForm;
