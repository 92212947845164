import {
  Button,
  Center,
  Container,
  Grid,
  Modal,
  Tabs,
  Text,
} from "@mantine/core";
import {
  IconBrandProducthunt,
  IconPlus,
  IconSpeakerphone,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import ProductCard from "../../components/Cards/ProductCard";
import FilterPanel, {
  FilterOption,
  SortOption,
} from "../../components/Filter/FilterPanel";
import { PRODUCT_CATEGORIES } from "../../constants/webData";
import { initialProducts } from "../../utils/mockData/productsMockData";
import { Product } from "../CollabsPage/Collabs.types";
import {
  Campaign,
  CampaignFormValues,
  CampaignStatus,
} from "./Campaigns/Campaign.types";
import CampaignForm from "./Campaigns/CampaignForm";
import CampaignItem from "./Campaigns/components/CampaignItem";
import ProductForm, { ProductFormValues } from "./components/ProductForm";

// Mock function to simulate API call
const createCampaign = async (
  campaignData: CampaignFormValues
): Promise<Campaign> => {
  // Simulate API delay
  await new Promise((resolve) => setTimeout(resolve, 1500));

  return {
    id: Date.now().toString(),
    name: campaignData.name,
    brandId: "brand-123", // Would come from auth context in real app
    products: initialProducts.filter((p) =>
      campaignData.products.includes(p.id)
    ),
    requirements: {
      videosDesired: campaignData.videosDesired,
      streamsDesired: campaignData.streamsDesired,
      affiliateGMVMinimum: campaignData.affiliateGMVMinimum,
    },
    suggestedBudget: campaignData.suggestedBudget || 0,
    actualBudget: campaignData.actualBudget || 0,
    status: CampaignStatus.Active,
    createdAt: new Date(),
    updatedAt: new Date(),
    collabsCreated: 0,
    collabsAccepted: 0,
    collabsCompleted: 0,
  };
};

function ProductsPage() {
  const [activeTab, setActiveTab] = useState<string | null>("products");
  const [products, setProducts] = useState<Product[]>(initialProducts);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState("name");
  const [editingProduct, setEditingProduct] = useState<Product | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Initialize filtered products
    setFilteredProducts(products);
  }, [products]);

  const handleAddProduct = (values: ProductFormValues) => {
    // Ensure price is stored as a number
    const parsedPrice = parseFloat(values.price.toString()) || 0;

    const newProduct: Product = {
      id: Date.now().toString(),
      name: values.name,
      image: values.image,
      description: values.description,
      price: parsedPrice,
      categories: values.categories,
      link: values.link,
    };

    setProducts([...products, newProduct]);
    setModalOpened(false);
  };

  const handleEditProduct = (values: ProductFormValues) => {
    if (!editingProduct) return;

    // Ensure price is stored as a number
    const parsedPrice = parseFloat(values.price.toString()) || 0;

    const updatedProduct: Product = {
      ...editingProduct,
      name: values.name,
      image: values.image,
      description: values.description,
      price: parsedPrice,
      categories: values.categories,
      link: values.link,
    };

    setProducts(
      products.map((product) =>
        product.id === editingProduct.id ? updatedProduct : product
      )
    );

    setEditingProduct(null);
    setModalOpened(false);
  };

  const openEditModal = (product: Product) => {
    setEditingProduct(product);
    setModalOpened(true);
  };

  const handleCloseModal = () => {
    setEditingProduct(null);
    setModalOpened(false);
  };

  const handleStartCampaign = async (values: CampaignFormValues) => {
    setIsLoading(true);
    try {
      const newCampaign = await createCampaign(values);
      setCampaigns([...campaigns, newCampaign]);
      setModalOpened(false);
      setActiveTab("campaigns");
    } catch (error) {
      console.error("Error creating campaign:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearFilters = () => {
    setSearchQuery("");
    setSelectedCategories([]);
    setSortBy("name");
  };

  const filterOptions: FilterOption[] = [
    {
      id: "search",
      label: "Search",
      type: "search",
      placeholder: "Search products...",
      value: searchQuery,
      onChange: setSearchQuery,
      searchFields: ["name", "description"],
    },
    {
      id: "categories",
      label: "Categories",
      type: "multiSelect",
      placeholder: "Filter by category",
      data: PRODUCT_CATEGORIES.map((cat) => ({ value: cat, label: cat })),
      value: selectedCategories,
      onChange: setSelectedCategories,
      filterFn: (product: Product, selectedCats: string[]) => {
        if (selectedCats.length === 0) return true;
        return product.categories.some((category) =>
          selectedCats.includes(category)
        );
      },
    },
  ];

  const sortOptions: SortOption[] = [
    {
      value: "name",
      label: "Name (A-Z)",
      sortFn: (a: Product, b: Product) => a.name.localeCompare(b.name),
    },
    {
      value: "price-asc",
      label: "Price (Low to High)",
      sortFn: (a: Product, b: Product) => a.price - b.price,
    },
    {
      value: "price-desc",
      label: "Price (High to Low)",
      sortFn: (a: Product, b: Product) => b.price - a.price,
    },
    {
      value: "newest",
      label: "Newest First",
      sortFn: (a: Product, b: Product) => parseInt(b.id) - parseInt(a.id),
    },
  ];

  return (
    <Container size="xl">
      {activeTab === "products" ? (
        <Button
          size="md"
          leftSection={<IconPlus size={16} />}
          onClick={() => {
            setEditingProduct(null);
            setModalOpened(true);
          }}
        >
          Add Product
        </Button>
      ) : (
        <Button
          leftSection={<IconPlus size={16} />}
          onClick={() => {
            setEditingProduct(null);
            setModalOpened(true);
          }}
          disabled={products.length === 0}
          variant="gradient"
          gradient={{ from: "brandOrange", to: "brandGreen", deg: 45 }}
          fw={600}
          size="md"
          style={{
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
            transform: "translateY(0)",
            transition: "transform 0.2s, box-shadow 0.2s",
            ":hover": {
              transform: "translateY(-2px)",
              boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          Start a Campaign
        </Button>
      )}

      <Tabs
        mt={"md"}
        value={activeTab}
        onChange={setActiveTab}
        variant="outline"
      >
        <Tabs.List
          grow
          mb="md"
          style={{ borderBottom: "2px solid var(--mantine-color-gray-3)" }}
        >
          <Tabs.Tab
            value="products"
            leftSection={<IconBrandProducthunt size={20} />}
            fz="lg"
            py="md"
          >
            Products
          </Tabs.Tab>
          <Tabs.Tab
            value="campaigns"
            leftSection={<IconSpeakerphone size={20} />}
            fz="lg"
            py="md"
          >
            Campaigns
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="products">
          <FilterPanel
            filterOptions={filterOptions}
            sortOptions={sortOptions}
            sortValue={sortBy}
            onSortChange={setSortBy}
            onClearFilters={handleClearFilters}
            data={products}
            onFilteredDataChange={setFilteredProducts}
          />

          {filteredProducts.length === 0 ? (
            <Center p="xl">
              <Text ta="center" fz="lg" c="dimmed">
                No products found. Try adjusting your search or add a new
                product.
              </Text>
            </Center>
          ) : (
            <Grid mt="md">
              {filteredProducts.map((product) => (
                <Grid.Col span={{ base: 12, sm: 6, md: 4 }} key={product.id}>
                  <ProductCard product={product} onEdit={openEditModal} />
                </Grid.Col>
              ))}
            </Grid>
          )}
        </Tabs.Panel>

        <Tabs.Panel value="campaigns">
          {campaigns.length === 0 ? (
            <Center p="xl">
              <Text ta="center" fz="lg" c="dimmed">
                No campaigns yet. Start a campaign to reach creators.
              </Text>
            </Center>
          ) : (
            <Grid mt="md">
              {campaigns.map((campaign) => (
                <Grid.Col span={12} key={campaign.id}>
                  <CampaignItem campaign={campaign} />
                </Grid.Col>
              ))}
            </Grid>
          )}
        </Tabs.Panel>
      </Tabs>

      <Modal
        opened={modalOpened}
        onClose={handleCloseModal}
        title={
          activeTab === "products"
            ? editingProduct
              ? "Edit Product"
              : "Add New Product"
            : "Start a Campaign"
        }
        size={activeTab === "products" ? "lg" : "xl"}
      >
        {activeTab === "products" ? (
          <ProductForm
            initialValues={editingProduct || undefined}
            onSubmit={editingProduct ? handleEditProduct : handleAddProduct}
            onCancel={handleCloseModal}
            submitLabel={editingProduct ? "Save Changes" : "Add Product"}
          />
        ) : (
          <CampaignForm
            products={products}
            onSubmit={handleStartCampaign}
            onCancel={handleCloseModal}
          />
        )}
      </Modal>
    </Container>
  );
}

export default ProductsPage;
