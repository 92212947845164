import { Product } from "../../CollabsPage/Collabs.types";

export enum CampaignStatus {
  Draft = "draft",
  Active = "active",
  Paused = "paused",
  Completed = "completed",
  Cancelled = "cancelled",
}

export interface CampaignRequirements {
  videosDesired: number;
  streamsDesired: number;
  affiliateGMVMinimum?: number;
}

export interface Campaign {
  id: string;
  name: string;
  brandId: string;
  products: Product[];
  requirements: CampaignRequirements;
  suggestedBudget: number;
  actualBudget: number;
  status: CampaignStatus;
  createdAt: Date;
  updatedAt: Date;
  collabsCreated: number;
  collabsAccepted: number;
  collabsCompleted: number;
}

export interface CampaignFormValues {
  name: string;
  products: string[]; // Product IDs
  videosDesired: number;
  streamsDesired: number;
  affiliateGMVMinimum?: number;
  suggestedBudget?: number;
  actualBudget?: number;
}
