import {
  ActionIcon,
  AppShell,
  Box,
  Container,
  Flex,
  Group,
  Menu,
  Text,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import {
  IconCompass,
  IconLogout,
  IconPackage,
  IconSettings,
  IconTag,
  IconUser,
} from "@tabler/icons-react";
import Cookies from "js-cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isLoggedInAtom, userInfoAtom } from "../atoms";

export default function BrandNavBar() {
  const [opened, { toggle }] = useDisclosure(false);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const isLoggedIn = useRecoilValue(isLoggedInAtom);
  const userInfo = useRecoilValue(userInfoAtom);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("jwt");
    window.location.href = "/";
  };

  const navItems = [
    { label: "Discover", icon: IconCompass, path: "/discover" },
    { label: "Products", icon: IconPackage, path: "/products" },
    { label: "Collabs", icon: IconTag, path: "/collabs" },
    { label: "Profile", icon: IconUser, path: "/profile" },
  ];

  const isActive = (path: string) => location.pathname === path;

  // Desktop navigation
  const DesktopNav = (
    <AppShell>
      <AppShell.Header>
        <Container size="xl" py="md">
          <Group justify="space-between">
            <Group>
              <Text
                fw={700}
                size="xl"
                component={Link}
                to="/"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Collabish
              </Text>
            </Group>

            <Group>
              {navItems.map((item) => (
                <UnstyledButton
                  key={item.label}
                  component={Link}
                  to={item.path}
                  style={{
                    padding: "8px 16px",
                    borderRadius: theme.radius.md,
                    backgroundColor: isActive(item.path)
                      ? theme.colors.blue[0]
                      : "transparent",
                    color: isActive(item.path)
                      ? theme.colors.blue[7]
                      : theme.colors.gray[7],
                    fontWeight: isActive(item.path) ? 600 : 400,
                  }}
                >
                  <Group gap="xs">
                    <item.icon size={18} />
                    <Text>{item.label}</Text>
                  </Group>
                </UnstyledButton>
              ))}
            </Group>

            {isLoggedIn ? (
              <Menu position="bottom-end" shadow="md" width={200}>
                <Menu.Target>
                  <Group gap="xs" style={{ cursor: "pointer" }}>
                    {/* <Avatar
                    src={userInfo?.profileImage}
                    radius="xl"
                    size="md"
                    color="blue"
                  >
                    {userInfo?.name?.charAt(0) || "U"}
                  </Avatar>
                  <Box style={{ maxWidth: 120 }}>
                    <Text size="sm" fw={500} truncate>
                      {userInfo?.name || "User"}
                    </Text>
                  </Box>
                  <IconChevronDown size={rem(16)} /> */}
                  </Group>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item leftSection={<IconUser size={14} />}>
                    Profile
                  </Menu.Item>
                  <Menu.Item leftSection={<IconSettings size={14} />}>
                    Settings
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    leftSection={<IconLogout size={14} />}
                    color="red"
                    onClick={handleLogout}
                  >
                    Logout
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ) : (
              <Group>
                <UnstyledButton
                  component={Link}
                  to="/login"
                  style={{
                    padding: "8px 16px",
                    borderRadius: theme.radius.md,
                  }}
                >
                  Login
                </UnstyledButton>
                <UnstyledButton
                  component={Link}
                  to="/signup"
                  style={{
                    padding: "8px 16px",
                    borderRadius: theme.radius.md,
                    backgroundColor: theme.colors.blue[6],
                    color: "white",
                  }}
                >
                  Sign Up
                </UnstyledButton>
              </Group>
            )}
          </Group>
        </Container>
      </AppShell.Header>
    </AppShell>
  );

  // Mobile bottom navigation
  const MobileNav = (
    <Box
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "white",
        borderTop: `1px solid ${theme.colors.gray[2]}`,
        zIndex: 1000,
      }}
    >
      <Flex justify="space-around" p="xs">
        {navItems.map((item) => (
          <UnstyledButton
            key={item.label}
            component={Link}
            to={item.path}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "4px",
              color: isActive(item.path)
                ? theme.colors.blue[7]
                : theme.colors.gray[6],
            }}
          >
            <ActionIcon
              variant={isActive(item.path) ? "light" : "subtle"}
              color={isActive(item.path) ? "blue" : "gray"}
              size="lg"
            >
              <item.icon size={22} />
            </ActionIcon>
            <Text size="xs" fw={isActive(item.path) ? 600 : 400}>
              {item.label}
            </Text>
          </UnstyledButton>
        ))}
      </Flex>
    </Box>
  );

  // Mobile header (simplified)
  const MobileHeader = (
    <AppShell>
      <AppShell.Header>
        <Container py="md">
          <Group justify="space-between">
            <Text
              fw={700}
              size="xl"
              component={Link}
              to="/"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Collabish
            </Text>

            {isLoggedIn ? (
              <></>
            ) : (
              // <Avatar
              //   src={userInfo?.profileImage}
              //   radius="xl"
              //   size="md"
              //   color="blue"
              //   component={Link}
              //   to="/profile"
              // >
              //   {userInfo?.name?.charAt(0) || "U"}
              // </Avatar>
              <UnstyledButton
                component={Link}
                to="/login"
                style={{
                  padding: "8px 16px",
                  borderRadius: theme.radius.md,
                  backgroundColor: theme.colors.blue[6],
                  color: "white",
                }}
              >
                Login
              </UnstyledButton>
            )}
          </Group>
        </Container>
      </AppShell.Header>
    </AppShell>
  );

  return (
    <>
      {isMobile ? (
        <>
          {MobileHeader}
          {MobileNav}
          {/* Add padding at the bottom to prevent content from being hidden behind the mobile nav */}
          <Box pb={60} />
        </>
      ) : (
        DesktopNav
      )}
    </>
  );
}
