// *** Display data system of record ***

export const PRODUCT_CATEGORIES = [
  "Beauty & Personal Care",
  "Books",
  "Clothing",
  "Electronics",
  "Food & Beverages",
  "Home & Kitchen",
  "Health & Wellness",
  "Jewelry",
  "Office Products",
  "Pet Supplies",
  "Sports & Outdoors",
  "Toys & Games",
];
