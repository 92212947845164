import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Modal,
  Paper,
  ScrollArea,
  Stack,
  Text,
  Textarea,
} from "@mantine/core";
import { Form, useForm } from "@mantine/form";
import { IconPaperclip, IconSend } from "@tabler/icons-react";
import { useState } from "react";
import { Collab } from "../../../pages/CollabsPage/Collabs.types";
import { UserRole } from "../../../pages/CollabsPage/CollabsPage";

interface Message {
  id: string;
  senderId: string;
  senderName: string;
  senderAvatar: string;
  content: string;
  timestamp: Date;
  isFromCurrentUser: boolean;
}

interface MessageModalProps {
  opened: boolean;
  onClose: () => void;
  collab: Collab | null;
  userRole: UserRole;
}

const MessageModal = ({
  opened,
  onClose,
  collab,
  userRole,
}: MessageModalProps) => {
  // Mock messages - in a real app, these would come from an API
  const [messages, setMessages] = useState<Message[]>([
    {
      id: "1",
      senderId: collab?.brand.id || "",
      senderName: collab?.brand.name || "",
      senderAvatar: collab?.brand.logo || "",
      content:
        "Hi there! I'm interested in working with you on this product. Would you be available to start next week?",
      timestamp: new Date(Date.now() - 86400000), // 1 day ago
      isFromCurrentUser: userRole === UserRole.Brand,
    },
    {
      id: "2",
      senderId: collab?.creator.id || "",
      senderName: collab?.creator.name || "",
      senderAvatar: collab?.creator.image || "",
      content:
        "Hello! Thanks for reaching out. I'd be happy to discuss this collaboration. Could you provide more details about the requirements?",
      timestamp: new Date(Date.now() - 43200000), // 12 hours ago
      isFromCurrentUser: userRole === UserRole.Creator,
    },
  ]);

  const form = useForm({
    initialValues: {
      message: "",
    },
    validate: {
      message: (value: string) =>
        value.trim().length === 0 ? "Message cannot be empty" : null,
    },
  });

  const handleSendMessage = (values: { message: string }) => {
    if (!collab) return;

    const newMessage: Message = {
      id: Date.now().toString(),
      senderId:
        userRole === UserRole.Brand ? collab.brand.id : collab.creator.id,
      senderName:
        userRole === UserRole.Brand ? collab.brand.name : collab.creator.name,
      senderAvatar:
        userRole === UserRole.Brand ? collab.brand.logo : collab.creator.image,
      content: values.message,
      timestamp: new Date(),
      isFromCurrentUser: true,
    };

    setMessages([...messages, newMessage]);
    form.reset();
  };

  if (!collab) return null;

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Group>
          <Text fw={700} size="lg">
            Messages: {collab.product.name}
          </Text>
        </Group>
      }
      size="lg"
      centered
    >
      <Stack h={500}>
        <Group gap="xs" mb="xs">
          <Avatar src={collab.brand.logo} size="sm" radius="xl" />
          <Text size="sm" fw={500}>
            {collab.brand.name}
          </Text>
          <Text size="sm" c="dimmed">
            and
          </Text>
          <Avatar src={collab.creator.image} size="sm" radius="xl" />
          <Text size="sm" fw={500}>
            {collab.creator.name}
          </Text>
        </Group>

        <Divider />

        <ScrollArea style={{ flex: 1 }} offsetScrollbars>
          <Stack gap="md" p="xs">
            {messages.map((message) => (
              <MessageBubble key={message.id} message={message} />
            ))}
          </Stack>
        </ScrollArea>

        <Divider />

        <Form form={form} onSubmit={handleSendMessage}>
          <Flex gap="md" align="flex-end">
            <Textarea
              placeholder="Type your message..."
              style={{ flex: 1 }}
              minRows={2}
              maxRows={4}
              autosize
              {...form.getInputProps("message")}
            />
            <Flex direction="column" gap="xs">
              <ActionIcon
                variant="subtle"
                color="gray"
                title="Attach file (coming soon)"
                disabled
              >
                <IconPaperclip size={20} />
              </ActionIcon>
              <Button
                type="submit"
                color="brandPurple"
                radius="xl"
                size="md"
                title="Send message"
              >
                <IconSend size={18} />
              </Button>
            </Flex>
          </Flex>
        </Form>
      </Stack>
    </Modal>
  );
};

const MessageBubble = ({ message }: { message: Message }) => {
  const formattedTime = message.timestamp.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const formattedDate = message.timestamp.toLocaleDateString([], {
    month: "short",
    day: "numeric",
  });

  return (
    <Flex
      justify={message.isFromCurrentUser ? "flex-end" : "flex-start"}
      gap="sm"
      align="flex-start"
    >
      {!message.isFromCurrentUser && (
        <Avatar src={message.senderAvatar} size="md" radius="xl" />
      )}
      <Box style={{ maxWidth: "75%" }}>
        <Paper
          p="sm"
          radius="md"
          bg={message.isFromCurrentUser ? "brandPurple.1" : "gray.1"}
          style={(theme) => ({
            borderBottomLeftRadius: message.isFromCurrentUser ? undefined : 0,
            borderBottomRightRadius: message.isFromCurrentUser ? 0 : undefined,
            borderColor: message.isFromCurrentUser
              ? theme.colors.brandPurple[3]
              : theme.colors.gray[3],
            borderWidth: 1,
            borderStyle: "solid",
          })}
        >
          {!message.isFromCurrentUser && (
            <Text size="sm" fw={600} mb={4}>
              {message.senderName}
            </Text>
          )}
          <Text size="sm">{message.content}</Text>
        </Paper>
        <Text
          size="xs"
          c="dimmed"
          mt={2}
          ta={message.isFromCurrentUser ? "right" : "left"}
        >
          {formattedDate} at {formattedTime}
        </Text>
      </Box>
      {message.isFromCurrentUser && (
        <Avatar src={message.senderAvatar} size="md" radius="xl" />
      )}
    </Flex>
  );
};

export default MessageModal;
