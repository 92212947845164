import { useEffect, useState } from "react";
import { mockCollabs } from "../../utils/mockData/collabsMockData";
import { ActionRequiredBy, Collab, CollabStatus } from "./Collabs.types";
import { UserRole } from "./CollabsPage";

interface ActionRequiredCounts {
  pending: number;
  accepted: number;
  discussing: number;
  history: number;
}

export const useCollabs = (role: UserRole) => {
  const [collabs, setCollabs] = useState<Collab[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    // In a real app, this would be an API call
    // For now, using mock data
    try {
      setLoading(true);
      // Simulate API call
      setTimeout(() => {
        setCollabs(mockCollabs);
        setLoading(false);
      }, 500);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Unknown error"));
      setLoading(false);
    }
  }, [role]);

  // Filter collabs by status
  const pendingCollabs = collabs.filter(
    (collab) => collab.status === CollabStatus.Pending
  );
  const acceptedCollabs = collabs.filter(
    (collab) => collab.status === CollabStatus.Accepted
  );
  const discussingCollabs = collabs.filter(
    (collab) => collab.status === CollabStatus.Discussing
  );
  const historyCollabs = collabs.filter(
    (collab) =>
      collab.status === CollabStatus.Completed ||
      collab.status === CollabStatus.Declined
  );

  // Count collabs that require action from the current user
  const actionRequiredCounts: ActionRequiredCounts = {
    pending: pendingCollabs.filter(
      (collab) =>
        (role === UserRole.Brand &&
          collab.actionRequiredBy === ActionRequiredBy.Brand) ||
        (role === UserRole.Creator &&
          collab.actionRequiredBy === ActionRequiredBy.Creator)
    ).length,
    accepted: acceptedCollabs.filter(
      (collab) =>
        (role === UserRole.Brand &&
          collab.actionRequiredBy === ActionRequiredBy.Brand) ||
        (role === UserRole.Creator &&
          collab.actionRequiredBy === ActionRequiredBy.Creator)
    ).length,
    discussing: discussingCollabs.filter(
      (collab) =>
        (role === UserRole.Brand &&
          collab.actionRequiredBy === ActionRequiredBy.Brand) ||
        (role === UserRole.Creator &&
          collab.actionRequiredBy === ActionRequiredBy.Creator)
    ).length,
    history: historyCollabs.filter(
      (collab) =>
        (role === UserRole.Brand &&
          collab.actionRequiredBy === ActionRequiredBy.Brand) ||
        (role === UserRole.Creator &&
          collab.actionRequiredBy === ActionRequiredBy.Creator)
    ).length,
  };

  function updateCollab(collabId: string, updates: Partial<Collab>) {
    setCollabs((prevCollabs) =>
      prevCollabs.map((collab) =>
        collab.id === collabId ? { ...collab, ...updates } : collab
      )
    );

    // In a real app, you would make an API call here
    // Example:
    // return apiClient.updateCollab(collabId, updates)
    //   .then(response => {
    //     // Update local state with response data if needed
    //     return response;
    //   });
  }

  return {
    pendingCollabs,
    acceptedCollabs,
    discussingCollabs,
    historyCollabs,
    actionRequiredCounts,
    updateCollab,
    loading,
    error,
  };
};
