import Cookies from "js-cookie";
import { UserType } from "../../gql/__generated__/graphql";

export interface UserInfo {
  email: string;
  userType: UserType;
}

export function isLoggedIn(): boolean {
  const jwt = Cookies.get("jwt");
  if (!jwt) return false;
  return true;
}

export function saveJWTCookie(jwt: string) {
  Cookies.set("jwt", jwt, { expires: 7, secure: true, sameSite: "strict" });
}

export function decodeJWT(): UserInfo | null {
  const jwt = Cookies.get("jwt");
  if (!jwt) return null;

  return JSON.parse(atob(jwt.split(".")[1]));
}
