import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import { Collab } from "../../pages/CollabsPage/Collabs.types";
import { UserRole } from "../../pages/CollabsPage/CollabsPage";

interface ConfirmationModalProps {
  opened: boolean;
  onClose: () => void;
  collab: Collab | null;
  action: "accept" | "decline";
  onConfirm: () => void;
  userRole: UserRole;
}

const ConfirmationModal = ({
  opened,
  onClose,
  collab,
  action,
  onConfirm,
  userRole,
}: ConfirmationModalProps) => {
  if (!collab) return null;

  const entityName =
    userRole === UserRole.Brand ? collab.creator.name : collab.brand.name;

  return (
    <Modal opened={opened} onClose={onClose} title="Confirm Action" centered>
      <Stack>
        <Text>
          Are you sure you want to {action} the collab from{" "}
          <Text span fw={600}>
            {entityName}
          </Text>{" "}
          for{" "}
          <Text span fw={600}>
            ${collab.price.toLocaleString()}
          </Text>
          ?
        </Text>

        <Text size="sm" c="dimmed">
          Product: {collab.product.name}
        </Text>

        <Group justify="flex-end" mt="md">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            color={action === "accept" ? "brandGreen" : "red"}
            onClick={onConfirm}
          >
            Yes, {action === "accept" ? "Accept" : "Decline"}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default ConfirmationModal;
