import { Badge, Button, Card, Group, Image, Stack, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { Product } from "../../pages/CollabsPage/Collabs.types";

interface ProductCardProps {
  product: Product;
  onEdit?: (product: Product) => void;
  compact?: boolean;
}

function ProductCard({ product, onEdit, compact = false }: ProductCardProps) {
  if (compact) {
    return (
      <Card shadow="sm" padding="xs" radius="md" withBorder>
        <Group align="flex-start">
          <Image
            src={product.image}
            width={80}
            height={80}
            alt={product.name}
            fallbackSrc="https://placehold.co/80x80?text=Product"
          />
          <Stack gap="xs" style={{ flex: 1 }}>
            <Text fw={500} size="sm" truncate>
              {product.name}
            </Text>
            <Text size="xs" c="dimmed" lineClamp={1}>
              {product.description}
            </Text>
            <Text fw={700} size="sm" c="blue">
              ${product.price.toFixed(2)}
            </Text>
          </Stack>
        </Group>
      </Card>
    );
  }

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Card.Section>
        <Image
          src={product.image}
          height={160}
          alt={product.name}
          fallbackSrc="https://placehold.co/400x200?text=Product+Image"
        />
      </Card.Section>

      <Stack mt="md" mb="xs">
        <Text fw={500} size="lg" truncate>
          {product.name}
        </Text>

        <Group gap={5}>
          {product.categories.map((category) => (
            <Badge key={category} color="blue" variant="light">
              {category}
            </Badge>
          ))}
        </Group>

        <Text size="sm" c="dimmed" lineClamp={2}>
          {product.description}
        </Text>

        <Text fw={700} size="lg" c="blue">
          ${product.price.toFixed(2)}
        </Text>
      </Stack>

      <Group mt="md">
        <Button
          variant="light"
          color="blue"
          fullWidth
          component="a"
          href={product.link}
          target="_blank"
        >
          View Product
        </Button>

        {onEdit && (
          <Button
            variant="outline"
            color="gray"
            fullWidth
            leftSection={<IconEdit size={16} />}
            onClick={() => onEdit(product)}
          >
            Edit
          </Button>
        )}
      </Group>
    </Card>
  );
}

export default ProductCard;
