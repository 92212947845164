import { SocialMedia } from "../../components/Cards/CreatorCard";

// Common review type for both Brand and Creator profiles
export interface Review {
  name: string;
  avatarUrl: string;
  rating: number;
  date: string;
  comment: string;
}

// Common base profile fields
export interface BaseProfile {
  id: string;
  name: string;
  logo: string;
  description: string;
  categories: string[];
  location?: string;
  email?: string;
  phone?: string;
  website?: string;
  rating: number;
  reviewCount: number;
  reviews: Review[];
}

// Brand-specific fields
export interface BrandProfile extends BaseProfile {
  foundedYear?: string;
  companySize?: string;
  brandStory?: string;
  products?: {
    id: string;
    name: string;
    price: number;
    imageUrl: string;
    description?: string;
    categories?: string[];
    link?: string;
  }[];
}

// Creator-specific fields
export interface CreatorProfile extends BaseProfile {
  socialMedia: SocialMedia;
  monthlyGMV?: number;
  engagementRate?: number;
  completedCollabs?: number;
  contentStyle?: string;
  audienceDemographics?: string;
  portfolio?: {
    id: string;
    title: string;
    type: string;
    description: string;
    imageUrl: string;
    link?: string;
  }[];
}

// Union type for either Brand or Creator profile
export type Profile = BrandProfile | CreatorProfile;

// Type guard to check if a profile is a Creator profile
export function isCreatorProfile(profile: Profile): profile is CreatorProfile {
  return "socialMedia" in profile;
}

// Type guard to check if a profile is a Brand profile
export function isBrandProfile(profile: Profile): profile is BrandProfile {
  return "products" in profile;
}
