import { Group } from "@mantine/core";
import React from "react";

const Footer: React.FC = () => {
  return (
    <Group className="center" w={"100%"} p={10}>
      <h5>Footer</h5>
    </Group>
  );
};

export default Footer;
