import { Badge, Grid, Group, Paper, Text, Title } from "@mantine/core";
import ProductCard from "../../../../components/Cards/ProductCard";
import { Campaign, CampaignStatus } from "../Campaign.types";

interface CampaignItemProps {
  campaign: Campaign;
}

function CampaignItem({ campaign }: CampaignItemProps) {
  const getStatusColor = (status: CampaignStatus) => {
    switch (status) {
      case CampaignStatus.Active:
        return "green";
      case CampaignStatus.Paused:
        return "yellow";
      case CampaignStatus.Completed:
        return "blue";
      case CampaignStatus.Cancelled:
        return "red";
      default:
        return "gray";
    }
  };

  return (
    <Paper p="md" withBorder>
      <Group justify="space-between" mb="xs">
        <Title order={4}>{campaign.name}</Title>
        <Badge color={getStatusColor(campaign.status)}>{campaign.status}</Badge>
      </Group>

      <Text size="sm" mb="md">
        Budget: ${campaign.actualBudget.toLocaleString()} • Created:{" "}
        {campaign.createdAt.toLocaleDateString()}
      </Text>

      <Group justify="space-between" mb="md">
        <div>
          <Text size="sm" fw={500}>
            Requirements
          </Text>
          <Text size="xs">Videos: {campaign.requirements.videosDesired}</Text>
          <Text size="xs">Streams: {campaign.requirements.streamsDesired}</Text>
          {campaign.requirements.affiliateGMVMinimum && (
            <Text size="xs">
              Min GMV: ${campaign.requirements.affiliateGMVMinimum}
            </Text>
          )}
        </div>

        <div>
          <Text size="sm" fw={500}>
            Progress
          </Text>
          <Text size="xs">Collabs Created: {campaign.collabsCreated}</Text>
          <Text size="xs">Collabs Accepted: {campaign.collabsAccepted}</Text>
          <Text size="xs">Collabs Completed: {campaign.collabsCompleted}</Text>
        </div>
      </Group>

      <Text size="sm" fw={500} mb="xs">
        Products:
      </Text>
      <Grid>
        {campaign.products.map((product) => (
          <Grid.Col span={{ base: 12, xs: 6, sm: 4, md: 3 }} key={product.id}>
            <ProductCard product={product} compact />
          </Grid.Col>
        ))}
      </Grid>
    </Paper>
  );
}

export default CampaignItem;
