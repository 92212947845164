import {
  Box,
  Button,
  Collapse,
  Grid,
  Group,
  MultiSelect,
  NumberInput,
  Paper,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import ProductCard from "../../../components/Cards/ProductCard";
import { Product } from "../../CollabsPage/Collabs.types";
import { CampaignFormValues } from "./Campaign.types";

interface CampaignFormProps {
  products: Product[];
  onSubmit: (values: CampaignFormValues) => void;
  onCancel: () => void;
}

function CampaignForm({ products, onSubmit, onCancel }: CampaignFormProps) {
  const minMediaCount = 10;

  const [opened, { toggle }] = useDisclosure(false);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [suggestedBudget, setSuggestedBudget] = useState<number | null>(null);
  const [isCalculatingBudget, setIsCalculatingBudget] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<CampaignFormValues>({
    initialValues: {
      name: "",
      products: [],
      videosDesired: 5,
      streamsDesired: 5,
      affiliateGMVMinimum: undefined,
      suggestedBudget: undefined,
      actualBudget: undefined,
    },
    validate: {
      name: (value) =>
        value.trim().length < 3
          ? "Campaign name must be at least 3 characters"
          : null,
      products: (value) =>
        value.length === 0
          ? "Select at least one product"
          : value.length > 5
          ? "Maximum 5 products allowed"
          : null,
      videosDesired: (value, values) => {
        if (value < 0) return "Cannot be negative";
        if (value > 1000) return "Maximum 1000 videos";
        if (value + (values.streamsDesired || 0) < minMediaCount)
          return `Total videos and streams must be at least ${minMediaCount}`;
        return null;
      },
      streamsDesired: (value, values) => {
        if (value < 0) return "Cannot be negative";
        if (value > 1000) return "Maximum 1000 streams";
        if ((values.videosDesired || 0) + value < minMediaCount)
          return `Total videos and streams must be at least ${minMediaCount}`;
        return null;
      },
      affiliateGMVMinimum: (value) => {
        if (value !== undefined && value < 0) return "Cannot be negative";
        return null;
      },
      actualBudget: (value, values) => {
        if (value === undefined || value === null) return "Budget is required";
        if (value <= 0) return "Budget must be greater than 0";
        if (values.suggestedBudget && value < values.suggestedBudget * 0.8)
          return "Budget is too low compared to suggestion";
        return null;
      },
    },
  });

  // Update selected products when form products change
  useEffect(() => {
    const selected = products.filter((product) =>
      form.values.products.includes(product.id)
    );
    setSelectedProducts(selected);
  }, [form.values.products, products]);

  const calculateSuggestedBudget = () => {
    setIsCalculatingBudget(true);

    // Simulate API call to calculate budget
    setTimeout(() => {
      // Simple budget calculation logic:
      // Base: $20 per video, $30 per stream
      // Add 20% if GMV minimum is set
      // Add $10 per product beyond the first one
      const videoCost = form.values.videosDesired * 20;
      const streamCost = form.values.streamsDesired * 30;
      const gmvFactor = form.values.affiliateGMVMinimum ? 1.2 : 1;
      const productFactor = (form.values.products.length - 1) * 10;

      const calculatedBudget = Math.round(
        (videoCost + streamCost) * gmvFactor + productFactor
      );

      setSuggestedBudget(calculatedBudget);
      form.setFieldValue("suggestedBudget", calculatedBudget);
      form.setFieldValue("actualBudget", calculatedBudget);

      setIsCalculatingBudget(false);
    }, 1000);
  };

  const handleProductSelect = (selectedIds: string[]) => {
    // Limit to 5 products
    if (selectedIds.length <= 5) {
      form.setFieldValue("products", selectedIds);
    } else {
      form.setFieldValue("products", selectedIds.slice(0, 5));
    }
  };

  const handleSubmit = (values: CampaignFormValues) => {
    setIsSubmitting(true);
    onSubmit(values);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack gap="md">
        <TextInput
          label="Campaign Name"
          placeholder="Enter campaign name"
          required
          {...form.getInputProps("name")}
        />

        <MultiSelect
          label="Select Products (max 5)"
          data={products.map((product) => ({
            value: product.id,
            label: product.name,
          }))}
          placeholder="Select products for this campaign"
          required
          maxValues={5}
          value={form.values.products}
          onChange={handleProductSelect}
          error={form.errors.products}
        />

        {selectedProducts.length > 0 && (
          <Box>
            <Text size="sm" fw={500} mb="xs">
              Selected Products:
            </Text>
            <Grid>
              {selectedProducts.map((product) => (
                <Grid.Col span={{ base: 12, sm: 6, md: 4 }} key={product.id}>
                  <ProductCard product={product} compact />
                </Grid.Col>
              ))}
            </Grid>
          </Box>
        )}

        <Group grow>
          <NumberInput
            label="Videos Desired"
            description="Number of videos you want creators to make"
            placeholder="Enter number of videos"
            min={0}
            max={1000}
            required
            {...form.getInputProps("videosDesired")}
          />

          <NumberInput
            label="Streams Desired"
            description="Number of livestreams you want creators to do"
            placeholder="Enter number of streams"
            min={0}
            max={1000}
            required
            {...form.getInputProps("streamsDesired")}
          />
        </Group>

        <Group>
          <Button variant="subtle" onClick={toggle} size="compact-md">
            {opened ? "Hide Advanced Options" : "Show Advanced Options"}
          </Button>
        </Group>

        <Collapse in={opened}>
          <Paper p="md" withBorder>
            <Stack>
              <Title order={5}>Advanced Options</Title>
              <NumberInput
                label="Affiliate GMV Minimum ($)"
                description="Minimum gross merchandise value each creator must generate"
                placeholder="Enter minimum GMV"
                min={0}
                {...form.getInputProps("affiliateGMVMinimum")}
              />
              <Text size="xs" c="dimmed">
                Note: Setting GMV minimum will make your campaign more expensive
              </Text>
            </Stack>
          </Paper>
        </Collapse>

        <Button
          onClick={calculateSuggestedBudget}
          disabled={
            !form.isValid("products") ||
            !form.isValid("videosDesired") ||
            !form.isValid("streamsDesired")
          }
          loading={isCalculatingBudget}
          color="blue"
          variant="outline"
          fullWidth
        >
          Determine Suggested Budget
        </Button>

        {suggestedBudget !== null && (
          <Paper p="md" withBorder bg="blue.0">
            <Stack gap="xs">
              <Title order={4}>Suggested Budget</Title>
              <Text size="xl" fw={700} c="blue">
                ${suggestedBudget.toLocaleString()}
              </Text>
              <Text size="sm">
                This budget is calculated based on your campaign requirements
                and will be used to compensate creators.
              </Text>
              <NumberInput
                label="Your Campaign Budget ($)"
                description="You can adjust this, but we recommend staying close to the suggestion"
                placeholder="Enter your budget"
                min={1}
                required
                {...form.getInputProps("actualBudget")}
              />
            </Stack>
          </Paper>
        )}

        <Group mt="xl">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={
              !form.isValid() || suggestedBudget === null || isSubmitting
            }
            loading={isSubmitting}
          >
            Launch Campaign
          </Button>
        </Group>
      </Stack>
    </form>
  );
}

export default CampaignForm;
