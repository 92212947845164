import { Group, SegmentedControl, Text } from "@mantine/core";
import { UserRole } from "../CollabsPage";

interface RoleToggleProps {
  activeRole: UserRole;
  onChange: (role: UserRole) => void;
}

const RoleToggle = ({ activeRole, onChange }: RoleToggleProps) => {
  return (
    <Group justify="center">
      <Text fw={500}>DEVELOPER MODE - View as:</Text>
      <SegmentedControl
        value={activeRole}
        onChange={(value) => onChange(value as UserRole)}
        data={[
          {
            value: UserRole.Brand,
            label: <Text fw={500}>Brand View</Text>,
          },
          {
            value: UserRole.Creator,
            label: <Text fw={500}>Creator View</Text>,
          },
        ]}
        size="md"
        color="brandGreen"
      />
    </Group>
  );
};

export default RoleToggle;
