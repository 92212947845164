import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Card,
  Divider,
  Flex,
  Group,
  Rating,
  SimpleGrid,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import {
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandTwitter,
  IconBrandYoutube,
  IconCash,
  IconHandGrab,
  IconTag,
  IconUsers,
} from "@tabler/icons-react";

export interface SocialMedia {
  tiktok?: number;
  instagram?: number;
  youtube?: number;
  twitter?: number;
}

export interface CreatorSummary {
  id: string;
  name: string;
  image: string;
  rating: number;
  description: string;
  monthlyGMV?: number;
  socialMedia: SocialMedia;
  categories: string[];
  completedCollabs: number;
}

interface CreatorCardProps {
  creator: CreatorSummary;
}

export function CreatorCard({ creator }: CreatorCardProps) {
  const theme = useMantineTheme();

  // Format follower counts (e.g., 1500 -> 1.5K)
  const formatFollowers = (count: number): string => {
    if (count >= 1000000) {
      return `${(count / 1000000).toFixed(1)}M`;
    }
    if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}K`;
    }
    return count.toString();
  };

  // Format currency (e.g., 15000 -> $15,000)
  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(amount);
  };

  // Get icon and color for each social platform
  const getSocialIcon = (platform: string) => {
    switch (platform) {
      case "twitter":
        return { icon: <IconBrandTwitter size={18} />, color: "#1DA1F2" };
      case "instagram":
        return { icon: <IconBrandInstagram size={18} />, color: "#C13584" };
      case "youtube":
        return { icon: <IconBrandYoutube size={18} />, color: "#FF0000" };
      case "tiktok":
        return { icon: <IconBrandTiktok size={18} />, color: "#000000" };
      default:
        return { icon: <IconUsers size={18} />, color: theme.colors.blue[6] };
    }
  };

  // Calculate total followers across all platforms
  const totalFollowers = Object.values(creator.socialMedia).reduce(
    (sum: number, count: number) => sum + count,
    0
  );

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Flex
        direction={{ base: "column", sm: "row" }}
        gap="md"
        align={{ base: "center", sm: "flex-start" }}
      >
        {/* Avatar */}
        <Avatar
          src={creator.image}
          size={80}
          radius={80}
          alt={`${creator.name}'s profile picture`}
        />

        {/* Creator info */}
        <Stack gap="xs" style={{ flex: 1 }}>
          <Group justify="space-between" wrap="nowrap">
            <Box>
              <Text fw={700} size="xl">
                {creator.name}
              </Text>
              <Group gap="xs">
                <Rating value={creator.rating} readOnly fractions={2} />
                <Text size="sm" c="dimmed">
                  ({creator.rating.toFixed(1)})
                </Text>
              </Group>
            </Box>

            {/* Total follower badge - visible on mobile and desktop */}
            <Badge
              size="lg"
              variant="light"
              leftSection={<IconUsers size={14} />}
              styles={{
                root: {
                  whiteSpace: "nowrap",
                  minWidth: "fit-content",
                  overflow: "visible",
                },
                label: {
                  overflow: "visible",
                  textOverflow: "clip",
                },
              }}
            >
              {formatFollowers(totalFollowers)}
            </Badge>
          </Group>

          <Text size="sm" lineClamp={3}>
            {creator.description}
          </Text>
        </Stack>
      </Flex>

      {/* Categories and Monthly GMV */}
      <SimpleGrid cols={2} mt="md">
        <Group gap="xs">
          {creator.categories.map((category) => (
            <Badge
              key={category}
              variant="outline"
              size="sm"
              leftSection={<IconTag size={12} />}
            >
              {category}
            </Badge>
          ))}
        </Group>

        {creator.monthlyGMV && (
          <Badge
            size="lg"
            color="green"
            variant="light"
            leftSection={<IconCash size={14} />}
          >
            {formatCurrency(creator.monthlyGMV)}/mo GMV
          </Badge>
        )}
      </SimpleGrid>

      <Divider my="md" />

      {/* Social Media Section */}
      <Flex gap="md" wrap="wrap" justify={{ base: "center", sm: "flex-start" }}>
        {/* Display each social media platform with followers */}
        {Object.entries(creator.socialMedia).map(
          ([platform, followers]) =>
            followers > 0 && (
              <Group key={platform} gap="xs">
                <ActionIcon
                  variant="light"
                  color={getSocialIcon(platform).color}
                  size="lg"
                  radius="xl"
                >
                  {getSocialIcon(platform).icon}
                </ActionIcon>
                <Stack gap={0}>
                  <Text size="xs" tt="uppercase" fw={500}>
                    {platform}
                  </Text>
                  <Text size="sm" fw={700}>
                    {formatFollowers(followers)}
                  </Text>
                </Stack>
              </Group>
            )
        )}

        {/* Completed Collaborations */}
        <Group gap="xs">
          <ActionIcon
            variant="light"
            color={theme.colors.blue[6]}
            size="lg"
            radius="xl"
          >
            <IconHandGrab size={18} />
          </ActionIcon>
          <Stack gap={0}>
            <Text size="xs" tt="uppercase" fw={500}>
              Collabs
            </Text>
            <Text size="sm" fw={700}>
              {formatFollowers(creator.completedCollabs)}
            </Text>
          </Stack>
        </Group>
      </Flex>
    </Card>
  );
}

export default function CreatorCardExample() {
  const creatorData: CreatorSummary = {
    id: "creator-123",
    name: "Alex Johnson",
    image:
      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3",
    rating: 4.7,
    description:
      "Digital artist and content creator specializing in motion graphics and 3D animation. Creating weekly tutorials and behind-the-scenes content.",
    socialMedia: {
      tiktok: 850000,
      instagram: 250000,
      youtube: 1250000,
      twitter: 75000,
    },
    monthlyGMV: 15000,
    categories: ["Digital Art", "3D Animation", "Tutorials"],
    completedCollabs: 32, // Added this field
  };

  return (
    <Box p="md" maw={500} mx="auto">
      <CreatorCard creator={creatorData} />
    </Box>
  );
}
