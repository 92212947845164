import { Divider, SimpleGrid, Stack, Text } from "@mantine/core";
import { useState } from "react";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import { cardAnimationSeconds } from "../../../constants/webControls";
import { ActionRequiredBy, Collab, CollabStatus } from "../Collabs.types";
import { UserRole } from "../CollabsPage";
import CollabCard from "./CollabsCard";

interface CollabsListProps {
  collabs: Collab[];
  role: UserRole;
  onUpdateCollab: (collabId: string, updates: Partial<Collab>) => void;
}

function CollabsList({ collabs, role, onUpdateCollab }: CollabsListProps) {
  const [confirmationModal, setConfirmationModal] = useState<{
    opened: boolean;
    collab: Collab | null;
    action: "accept" | "decline";
  }>({
    opened: false,
    collab: null,
    action: "accept",
  });

  const [animatingCollabs, setAnimatingCollabs] = useState<{
    [collabId: string]: "accept" | "decline" | null;
  }>({});

  const openConfirmationModal = (
    collab: Collab,
    action: "accept" | "decline"
  ) => {
    setConfirmationModal({
      opened: true,
      collab,
      action,
    });
  };

  const closeConfirmationModal = () => {
    setConfirmationModal({
      ...confirmationModal,
      opened: false,
    });
  };

  const handleConfirmAction = () => {
    if (!confirmationModal.collab) return;

    const collabId = confirmationModal.collab.id;
    const action = confirmationModal.action;

    // Close the modal
    closeConfirmationModal();

    // Show animation
    setAnimatingCollabs({
      ...animatingCollabs,
      [collabId]: action,
    });

    // After animation completes, update the collab
    setTimeout(() => {
      if (action === "accept") {
        onUpdateCollab(collabId, {
          status: CollabStatus.Accepted,
          actionRequiredBy: ActionRequiredBy.Creator,
        });
      } else {
        onUpdateCollab(collabId, {
          status: CollabStatus.Declined,
          actionRequiredBy: ActionRequiredBy.None,
        });
      }

      // Clear animation state
      setAnimatingCollabs({
        ...animatingCollabs,
        [collabId]: null,
      });
    }, cardAnimationSeconds * 1000); // Animation duration
  };

  const handleMessage = (collabId: string) => {
    console.log("Message for collab:", collabId);
    // Implement messaging logic
  };

  // Filter collabs that require action from the current user
  const actionRequiredCollabs = collabs.filter(
    (collab) =>
      (role === UserRole.Brand &&
        collab.actionRequiredBy === ActionRequiredBy.Brand) ||
      (role === UserRole.Creator &&
        collab.actionRequiredBy === ActionRequiredBy.Creator)
  );

  // Filter collabs that are awaiting response (no action required from current user)
  const awaitingResponseCollabs = collabs.filter(
    (collab) =>
      !(
        (role === UserRole.Brand &&
          collab.actionRequiredBy === ActionRequiredBy.Brand) ||
        (role === UserRole.Creator &&
          collab.actionRequiredBy === ActionRequiredBy.Creator)
      )
  );

  if (collabs.length === 0) {
    return (
      <Text ta="center" c="dimmed" py="xl">
        No collabs found
      </Text>
    );
  }

  return (
    <Stack gap="md">
      <ConfirmationModal
        opened={confirmationModal.opened}
        onClose={closeConfirmationModal}
        collab={confirmationModal.collab}
        action={confirmationModal.action}
        onConfirm={handleConfirmAction}
        userRole={role}
      />

      {actionRequiredCollabs.length > 0 && (
        <>
          <Text fw={500} size="lg">
            Action Required ({actionRequiredCollabs.length})
          </Text>
          <SimpleGrid
            cols={{ base: 1, sm: 2, md: 3 }}
            spacing="md"
            verticalSpacing="md"
          >
            {actionRequiredCollabs.map((collab) => (
              <CollabCard
                key={collab.id}
                collab={collab}
                userRole={role}
                onMessage={handleMessage}
                onOpenConfirmation={openConfirmationModal}
                animationAction={animatingCollabs[collab.id] || null}
              />
            ))}
          </SimpleGrid>
          {awaitingResponseCollabs.length > 0 && <Divider my="md" />}
        </>
      )}

      {awaitingResponseCollabs.length > 0 && (
        <>
          <Text fw={500} size="lg">
            Awaiting Response ({awaitingResponseCollabs.length})
          </Text>
          <SimpleGrid
            cols={{ base: 1, sm: 2, md: 3 }}
            spacing="md"
            verticalSpacing="md"
          >
            {awaitingResponseCollabs.map((collab) => (
              <CollabCard
                key={collab.id}
                collab={collab}
                userRole={role}
                onMessage={handleMessage}
                onOpenConfirmation={openConfirmationModal}
                animationAction={animatingCollabs[collab.id] || null}
              />
            ))}
          </SimpleGrid>
        </>
      )}
    </Stack>
  );
}

export default CollabsList;
