import { Product } from "../../pages/CollabsPage/Collabs.types";

// Sample initial products
export const initialProducts: Product[] = [
  {
    id: "1",
    name: "Wireless Headphones",
    image: "https://images.unsplash.com/photo-1505740420928-5e560c06d30e",
    description:
      "Premium noise-cancelling wireless headphones with 30-hour battery life.",
    price: 199.99,
    categories: ["Electronics"],
  },
  {
    id: "2",
    name: "Smart Watch",
    image: "https://images.unsplash.com/photo-1523275335684-37898b6baf30",
    description:
      "Fitness tracker with heart rate monitor, GPS, and smartphone notifications.",
    price: 249.99,
    categories: ["Electronics", "Health & Wellness"],
  },
];
